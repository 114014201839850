import { Button, Modal } from "antd";
import { useRecoilState } from "recoil";
import { isOpenState } from "../../../state/atoms/isOpen";

const DemandSignInFreeeModal = () => {
  const [isOpenSignUpFreeeModal, setIsOpenSignUpFreeeModal] = useRecoilState(isOpenState("signUpFreeeModal"));

  return (
    <Modal
      title="freee連携"
      visible={isOpenSignUpFreeeModal}
      onCancel={() => {
        setIsOpenSignUpFreeeModal(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setIsOpenSignUpFreeeModal(false);
          }}
        >
          戻る
        </Button>,
      ]}
    >
      <p>freeeにログインして連携を完了してください</p>
      <Button
        type="primary"
        onClick={() => {
          window.open(process.env.REACT_APP_FREEE_AUTH_URL, "_blank");
        }}
        style={{ marginRight: "10px" }}
      >
        freeeにログイン
      </Button>
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        ログイン完了
      </Button>
    </Modal>
  );
};

export default DemandSignInFreeeModal;
