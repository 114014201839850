import { PageHeader } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuthenticator } from "@aws-amplify/ui-react";

const StyledHeader = styled.header`
  border: 1px solid rgb(235, 237, 240);
  background-color: #f5f5f5;
  height: 12;
`;

const ProjectListHeader = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <StyledHeader>
      <PageHeader
        title="ChainChaser"
        subTitle="for sales management"
        extra={[
          <>
            <div>
              <nav key="nav">
                <Link key="1" to="/">
                  プロジェクト一覧
                </Link>{" "}
                |{" "}
                <Link key="2" to="/settings/userList">
                  ユーザー管理
                </Link>{" "}
              </nav>
            </div>
            <div>
              <span>{user.attributes.email} </span>
            </div>
          </>,
          <button onClick={signOut} key="signOutButton">
            Sign Out
          </button>,
        ]}
      />
    </StyledHeader>
  );
};

export default ProjectListHeader;
