import { useState, useEffect } from "react";
import { useRecoilStateLoadable } from "recoil";
import { axiosClient } from "../../../common/axiosClient";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { contractAddressListState } from "../../../state/atoms/contractAddressListAtom";
import { notifyError } from "../../../common/notify";

export const useContractAddress = () => {
  const { projectId } = useParams();

  const [contractAddressListLoadable, setContractAddressList] = useRecoilStateLoadable(
    contractAddressListState(projectId)
  );

  let isLoadingContractAddressList;
  switch (contractAddressListLoadable.state) {
    case "hasValue":
      isLoadingContractAddressList = false;
      break;
    case "loading":
      isLoadingContractAddressList = true;
      break;
    case "hasError":
      throw contractAddressListLoadable.contents;
  }

  const registerContractAddress = async (postObject) => {
    if (postObject.methodNamesAggregated !== undefined) {
      postObject.methodNamesAggregated = postObject.methodNamesAggregated.replace(/\s+/g, "").split(",");
    }

    const result = await axiosClient.post(`/${projectId}/contractAddress/register`, postObject);

    setContractAddressList((prev) => [...prev, result.data]);

    return { contractAddressListLoadable };
  };

  const updateContractAddress = async (values) => {
    const postObject = {
      contractAddress: values.contractAddress,
      name: values.name,
      memo: values.memo,
      themeColor: values.themeColor,
      methodNamesAggregated:
        values.methodNamesAggregated === undefined
          ? undefined
          : values.methodNamesAggregated.replace(/\s+/g, "").split(","), // スペースは取り除く
    };
    try {
      const result = await axiosClient.post(
        `${process.env.REACT_APP_API_HOST}/${projectId}/contractAddress/update`,
        postObject
      );

      setContractAddressList((prev) => [
        ...prev.filter((ca) => ca.contractAddress !== result.data.contractAddress),
        result.data,
      ]);
    } catch (e) {
      console.log(e);
      notifyError("編集に失敗しました");
    }
  };

  const deleteContractAddress = async (postObject) => {
    let success = false;
    try {
      await axiosClient.post(`/${projectId}/contractAddress/delete`, postObject);
      setContractAddressList(
        contractAddressListLoadable.contents.filter((ca) => ca.contractAddress !== postObject.contractAddress)
      );
      success = true;
    } catch (e) {
      console.error(e);
    }
    return success;
  };

  return {
    contractAddressList: contractAddressListLoadable.contents,
    isLoadingContractAddressList,
    registerContractAddress,
    updateContractAddress,
    deleteContractAddress,
  };
};

export const useContractMetaData = () => {
  const INITIAL_VALUE_CONTRACT_META_DATA = {
    contractAddress: "",
    contractName: "",
    symbol: "",
    contractType: "",
  };
  const [contractMetaData, setContractMetaData] = useState(INITIAL_VALUE_CONTRACT_META_DATA);
  const [loadingContractMetaData, setLoadingContractMetaData] = useState(false);

  const fetchContractMetaData = (chainId, contractAddress) => {
    useEffect(() => {
      const fetchMetaData = async () => {
        setLoadingContractMetaData(true);
        const result = await axiosClient.get(`/contractAddress/metadata?ca=${contractAddress}&chainId=${chainId}`);
        setContractMetaData(result.data.result);
        setLoadingContractMetaData(false);
      };

      // 入力値が不正な形式の場合は API を call しない
      ethers.utils.isAddress(contractAddress) ? fetchMetaData() : setContractMetaData(INITIAL_VALUE_CONTRACT_META_DATA);
    }, [chainId, contractAddress]);

    return { contractMetaData, loadingContractMetaData };
  };

  return { fetchContractMetaData };
};
