import { atomFamily, selectorFamily } from "recoil";
import { axiosClient } from "../../common/axiosClient";

//参考：https://github.com/TomoyukiMatsuda/React-pra/tree/next-sample/axios-recoil/next-sample/src/grobalStates
export const walletAddressListState = atomFamily({
  key: "WalletAddressList",
  default: selectorFamily({
    key: "WalletAddressList/Default",
    get: (projectId) => async () => {
      const response = await axiosClient.get(`/${projectId}/walletAddress/list`);
      return response.data.result;
    },
  }),
});
