import { Alert } from "antd";

import { Link, useParams } from "react-router-dom";

const FreeeTemplateEmpty = () => {
  const projectId = useParams().projectId;
  return (
    <div style={{ height: "160px" }}>
      <Alert
        style={{ position: "absolute", top: "56%", left: "50%", transform: "translate(-50%, -50%)" }}
        message={
          <>
            <div>テンプレートが未登録です。</div>
            <Link to={`/${projectId}/settings/freee`}>freee連携設定</Link>
            <span>でテンプレートを作成してください。</span>
          </>
        }
        type="warning"
      />
    </div>
  );
};
export default FreeeTemplateEmpty;
