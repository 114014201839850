import { useEffect, useState } from "react";
import { Form, Input, Spin, Select, Modal, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { createProject, updateProject } from "../api";
import { projectListState } from "../../../state/atoms/projectListAtom";
import { isOpenState } from "../../../state/atoms/isOpen";
import { notifyError } from "../../../common/notify";

const layout = {
  labelCol: {
    offset: 1,
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const options = [];
for (let i = 1; i < 13; i++) {
  options.push({
    label: `${i}月`,
    value: i,
    key: i,
  });
}

const RegisterProject = ({ edit = false, title = "新しいプロジェクトを作成", okText = "作成", formData = {} }) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const [projectList, setProjectList] = useRecoilState(projectListState);
  const [modalIsOpen, setIsOpen] = useRecoilState(isOpenState("projectRegistrationModal"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [projectList]);

  const onUpdateProject = async () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        if (edit) {
          const result = await updateProject(projectId, values.name, values.beginningMonth, values.description);
          console.log(44343, values, result);
          setProjectList((prev) => {
            return {
              maximumNumberOfProject: prev.maximumNumberOfProject,
              projects: [...prev.projects.filter((p) => p.projectId !== result.projectId), result],
            };
          });
        } else {
          const result = await createProject(values.name, values.beginningMonth, values.description);
          setProjectList((prev) => {
            return { maximumNumberOfProject: prev.maximumNumberOfProject, projects: [...prev.projects, result] };
          });
        }
        setLoading(false);
        setIsOpen(false);
        form.resetFields();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      <Modal
        title={title}
        width={1000}
        visible={modalIsOpen}
        okText={okText}
        cancelText="戻る"
        onOk={onUpdateProject}
        onCancel={() => setIsOpen(false)}
      >
        <Spin spinning={loading}>
          <Form {...layout} form={form} name="control-hooks">
            <Form.Item
              name="name"
              label="プロジェクト名"
              rules={[
                {
                  required: true,
                  message: "プロジェクト名を入力してください",
                },
                {
                  max: 40,
                  message: "40文字以下の名前を設定してください",
                },
                {
                  message: "このプロジェクト名は既に登録されています",
                  validator: (_, value) => {
                    if (
                      typeof value !== "undefined" &&
                      value !== "" &&
                      projectList.projects
                        .map((pj) => pj.name)
                        .filter((n) => n !== formData.name)
                        .includes(value)
                    ) {
                      return Promise.reject(new Error("This name has already been registered."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Example DAO" />
            </Form.Item>
            <Form.Item
              name="beginningMonth"
              label={
                <>
                  <span>開始月</span>
                  <Tooltip title="会計年度など一年間の区切りとなる月を設定してください。後から変更することも可能です。">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "開始月を入力してください",
                },
              ]}
            >
              <Select
                style={{
                  width: 120,
                }}
                options={options}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="概要"
              rules={[
                {
                  max: 400,
                  message: "400文字以下で設定してください",
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="プロジェクトの概要を入力してください" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default RegisterProject;
