import { useState } from "react";
import { Form, Input, Spin, Modal } from "antd";
import { useRecoilState } from "recoil";
import { createUser } from "../api";
import { userListState } from "../../../state/atoms/userListAtom";
import { isOpenState } from "../../../state/atoms/isOpen";

const layout = {
  labelCol: {
    offset: 1,
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const RegisterUser = () => {
  const [form] = Form.useForm();
  const [userList, setUserList] = useRecoilState(userListState);
  const [modalIsOpen, setIsOpen] = useRecoilState(isOpenState("userRegistrationModal"));
  const [loading, setLoading] = useState(false);

  const onCreateUser = async () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const result = await createUser(values.email);
        setUserList((prev) => [...prev, result]);
        setLoading(false);
        setIsOpen(false);
        form.resetFields();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        title="新しいユーザーを作成"
        width={1000}
        visible={modalIsOpen}
        okText="作成"
        cancelText="戻る"
        onOk={onCreateUser}
        onCancel={() => setIsOpen(false)}
      >
        <Spin spinning={loading}>
          <Form {...layout} form={form} name="control-hooks">
            <Form.Item
              name="email"
              label="メールアドレス"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "メールアドレスを入力してください",
                },
                {
                  message: "このメールアドレスは既に登録されています",
                  validator: (_, value) => {
                    if (
                      typeof value !== "undefined" &&
                      value !== "" &&
                      userList.map((user) => user.email).includes(value)
                    ) {
                      return Promise.reject(new Error("This address has already been registered."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="example@example.com" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default RegisterUser;
