import { notification } from "antd";

export const notifyError = (msg, props) => {
  notification.error({
    message: msg,
    ...props,
  });
};

export const notifySuccess = (msg, props) => {
  notification.success({
    message: msg,
    ...props,
  });
};
