import { atomFamily, selectorFamily } from "recoil";
import { axiosClient } from "../../common/axiosClient";

//参考：https://recoiljs.org/docs/guides/asynchronous-data-queries#queries-with-parameters
export const summaryState = atomFamily({
  key: "Summary",
  default: selectorFamily({
    key: "Summary/Default",
    get: (projectId) => async () => {
      return await fetchSummaries(projectId);
    },
  }),
});

const fetchSummaries = async (projectId) => {
  const response = await axiosClient.get(`/${projectId}/sales`);

  const result = response.data.map((salesMonthly) => {
    const sumMonthly = salesMonthly.sales.reduce(
      (result, current) => {
        result.depositsJpy += current.depositsJpy;
        result.salesValueJpy += current.salesValueJpy.reduce((prev, current) => prev + current.sales, 0);
        result.withdrawalsExceptGasJpy += current.withdrawalsExceptGasJpy;
        result.gasJpy += current.gasJpy;
        result.depositsCount += current.depositsCount;
        result.withdrawalsCount += current.withdrawalsCount;
        return result;
      },
      {
        depositsJpy: 0,
        salesValueJpy: 0,
        withdrawalsExceptGasJpy: 0,
        gasJpy: 0,
        depositsCount: 0,
        withdrawalsCount: 0,
      }
    );
    return {
      key: salesMonthly.dataType.split("#")[1],
      lastSyncedTime: salesMonthly.lastSyncedTime,
      ...sumMonthly,
      detailsByWalletAddress: salesMonthly.sales,
      transactionCount: salesMonthly.transactionCount,
      methodCallCount: salesMonthly.methodCall,
    };
  });

  return result;
};
