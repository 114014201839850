import { Button, Spin, Table, Tag, Tooltip, Form, Radio, Row, Col } from "antd";
import { PlusOutlined, MinusOutlined, RightOutlined } from "@ant-design/icons";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Link, useParams } from "react-router-dom";
import { endOfMonth, format, parse, fromUnixTime } from "date-fns";
import styled from "styled-components/macro"; // ないと css が動かない
import CustomEmpty from "../../../components/CustomEmpty";
import DemandSignInFreeeModal from "../../freee/components/DemandSignInFreeeModal";
import { addUnitYen } from "../../../common/unit";
import { isOpenWithParamsState } from "../../../state/atoms/isOpenWithParamsAtom";
import { projectListState } from "../../../state/atoms/projectListAtom";
import { useFetchSalesSummary } from "../api";
import { useWalletAddress } from "../../walletAddress/api";
import { useFreeeTemplates } from "../../freee/api";
import { useSearchPeriod } from "../../transaction/hooks/useSearchPeriod";
import { useContractAddress } from "../../contractAddress/api";
import { isOpenState } from "../../../state/atoms/isOpen";
import { useEffect, useState } from "react";

const createFiscalYearList = (nowDate, beginningMonth) => {
  const currentYear = nowDate.getFullYear();
  const currentMonth = nowDate.getMonth() + 1;

  // 2020年から現在の年までの年のリストを生成
  const fiscalYearList = [];
  for (let year = 2022; year < currentYear || (year === currentYear && beginningMonth <= currentMonth); year++) {
    fiscalYearList.push(year);
  }

  return fiscalYearList;
};

const Sales = () => {
  const [form] = Form.useForm();
  const fiscalYear = Form.useWatch("fiscalYear", form);
  const { projectId } = useParams();
  const projectList = useRecoilValue(projectListState);
  const currentProject = projectList.projects.find((project) => project.projectId === projectId);
  const beginningMonth = currentProject.beginningMonth !== undefined ? currentProject.beginningMonth : 1;

  const setIsOpenFreeeSyncModal = useSetRecoilState(isOpenWithParamsState("freeeSyncModal"));

  const { walletAddressList, isLoadingWalletAddressList } = useWalletAddress();
  const { summary, isLoadingSummary } = useFetchSalesSummary();
  const { contractAddressList, isLoadingContractAddressList } = useContractAddress();
  const { updateSearchPeriod } = useSearchPeriod();
  const { freeeTemplates, getTemplates } = useFreeeTemplates();
  const setIsOpenSignUpFreeeModal = useSetRecoilState(isOpenState("signUpFreeeModal"));
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const fiscalYearList = createFiscalYearList(new Date(), beginningMonth);
  useEffect(() => {
    form.setFieldValue("fiscalYear", fiscalYearList.at(-1));
  }, []);

  if (isLoadingSummary || isLoadingContractAddressList || isLoadingWalletAddressList) return <Spin />;

  const onClickFreeeSync = async (month) => {
    try {
      setIsLoadingTemplates(true);
      if (freeeTemplates.templates.length === 0) await getTemplates();
      setIsOpenFreeeSyncModal({ status: true, params: { month } });
    } catch (e) {
      if (e.response && e.response.status === 401) {
        console.log(401);
        setIsOpenFreeeSyncModal({ status: false, params: {} });
        setIsOpenSignUpFreeeModal(true);
      } else {
        console.log(500, e);
        setIsOpenFreeeSyncModal({ status: false, params: {} });
        // todo: 何かアラートを出す
      }
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  const columns = [
    {
      title: <div css="text-align: center;">年月</div>,
      dataIndex: "key",
      key: "key",
      align: "right",
    },
    {
      title: <div css="text-align: center;">入金額</div>,
      dataIndex: "depositsJpy",
      key: "depositsJpy",
      align: "right",
      render: (text) => addUnitYen(text),
    },
    {
      title: <div css="text-align: center;">売上金額</div>,
      dataIndex: "salesValueJpy",
      key: "salesValueJpy",
      align: "right",
      render: (text) => addUnitYen(text),
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="GAS代は含まない">出金額</Tooltip>
        </div>
      ),
      dataIndex: "withdrawalsExceptGasJpy",
      key: "withdrawalsExceptGasJpy",
      align: "right",
      render: (text) => addUnitYen(text),
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="Gas代">手数料</Tooltip>
        </div>
      ),
      dataIndex: "gasJpy",
      key: "gasJpy",
      align: "right",
      render: (text) => addUnitYen(text),
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="登録ウォレットに入金があったトランザクション数">入金数</Tooltip>
        </div>
      ),
      dataIndex: "depositsCount",
      key: "depositsCount",
      align: "right",
      render: (text) => <span>{text} 回</span>,
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="登録ウォレットから出金があったトランザクション数">出金数</Tooltip>
        </div>
      ),
      dataIndex: "withdrawalsCount",
      key: "withdrawalsCount",
      align: "right",
      render: (text) => <span>{text} 回</span>,
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="freeeにデータを送信">外部連携</Tooltip>
        </div>
      ),
      dataIndex: "freeeSync",
      key: "freeeSync",
      align: "right",
      render: (_, record) => <Button onClick={() => onClickFreeeSync(record.key)}>freeeに連携</Button>,
    },
    {
      title: "取引明細",
      dataIndex: "key",
      key: "list",
      align: "center",
      render: (text) => (
        <Link
          to={`/${projectId}/transaction`}
          onClick={() => {
            updateSearchPeriod({
              since: format(parse(text, "yyyy-MM", new Date()), "yyyy-MM-dd"),
              until: format(endOfMonth(parse(text, "yyyy-MM", new Date())), "yyyy-MM-dd"),
            });
          }}
        >
          <Button shape="circle" icon={<RightOutlined />} />
        </Link>
      ),
    },
  ];

  const columnsWalletAddress = [
    {
      title: "ウォレット",
      dataIndex: "walletAddress",
      key: "walletAddress",
      align: "center",
      //width: 300,
      render: (text) => {
        const wa = walletAddressList.filter((e) => e.walletAddress === text)[0];
        return {
          props: {
            style: { width: "200px", textAlign: "center" },
          },
          children: (
            <Tooltip title={text}>
              <Tag color={wa.themeColor} key={text}>
                {wa.name}
              </Tag>
            </Tooltip>
          ),
        };
      },
    },
    {
      title: <div css="text-align: center;">入金額</div>,
      dataIndex: "depositsJpy",
      key: "depositsJpy",
      render: (text) => <div css="text-align: right;">{addUnitYen(text)}</div>,
    },
    {
      title: <div css="text-align: center;">出金額</div>,
      dataIndex: "withdrawalsExceptGasJpy",
      key: "withdrawalsExceptGasJpy",
      render: (text) => <div css="text-align: right;">{addUnitYen(text)}</div>,
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="Gas代">手数料</Tooltip>
        </div>
      ),
      dataIndex: "gasJpy",
      key: "gasJpy",
      render: (text) => <div css="text-align: right;">{addUnitYen(text)}</div>,
    },
  ];
  const columnsContractAddress = [
    {
      title: "NFT / コントラクト",
      dataIndex: "contractAddress",
      key: "contractAddress",
      align: "center",
      //width: 300,
      render: (text) => {
        let ca;
        if (text === "other") {
          ca = {
            name: "その他",
            themeColor: "gray",
          };
        } else {
          ca = contractAddressList.filter((e) => e.contractAddress === text)[0];
        }
        return {
          props: {
            style: { width: "200px", textAlign: "center" },
          },
          children: (
            <div css="width: 200px; text-align: center;">
              <Tooltip title={text}>
                <Tag color={ca.themeColor} key={text}>
                  {ca.name}
                </Tag>
              </Tooltip>
            </div>
          ),
        };
      },
    },
    {
      title: <div css="text-align: center;">売上金額</div>,
      dataIndex: "sales",
      key: "sales",
      align: "right",
      render: (text) => {
        return {
          props: { style: { width: "200px" } },
          children: addUnitYen(text),
        };
      },
    },
  ];

  return (
    <>
      <DemandSignInFreeeModal />
      <div style={{ height: "10px" }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "1200px" }}>
          <Row>
            <Col span={12}>
              <Form form={form}>
                <Form.Item name="fiscalYear">
                  <Radio.Group>
                    {fiscalYearList.map((y) => (
                      <Radio.Button value={y} key={y}>
                        {y}年度
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <div style={{ float: "right" }}>
                集計時刻: {fromUnixTime(projectList.lastCalculatedTime).toLocaleString("ja-JP")}
              </div>
            </Col>
          </Row>
          <Table
            locale={{
              emptyText: <CustomEmpty />,
            }}
            columns={columns}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <Button shape="circle" icon={<MinusOutlined />} onClick={(e) => onExpand(record, e)} />
                ) : (
                  <Button shape="circle" icon={<PlusOutlined />} onClick={(e) => onExpand(record, e)} />
                ),
              expandedRowRender: (record) => {
                return (
                  <>
                    <Table
                      style={{ maxWidth: "900px" }}
                      locale={{
                        emptyText: <CustomEmpty />,
                      }}
                      columns={columnsWalletAddress}
                      dataSource={record.detailsByWalletAddress}
                      pagination={false}
                      size="middle"
                      expandable={{
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            <Button shape="circle" icon={<MinusOutlined />} onClick={(e) => onExpand(record, e)} />
                          ) : (
                            <Button shape="circle" icon={<PlusOutlined />} onClick={(e) => onExpand(record, e)} />
                          ),
                        expandedRowRender: (record) => {
                          return (
                            <>
                              <Table
                                style={{ maxWidth: "600px" }}
                                locale={{
                                  emptyText: <CustomEmpty />,
                                }}
                                columns={columnsContractAddress}
                                dataSource={record.salesValueJpy}
                                pagination={false}
                                size="middle"
                              />
                            </>
                          );
                        },
                      }}
                    />
                  </>
                );
              },
              defaultExpandedRowKeys: [format(new Date(), "yyyy-MM")],
            }}
            loading={
              isLoadingSummary || isLoadingWalletAddressList || isLoadingContractAddressList || isLoadingTemplates
            }
            dataSource={summary.filter((s) => {
              const targetDate = new Date(s.key);
              const startDate = new Date(`${fiscalYear}-${beginningMonth}`);
              const endDate = new Date(startDate);
              endDate.setFullYear(startDate.getFullYear() + 1);
              return startDate <= targetDate && targetDate < endDate;
            })}
            pagination={false}
            sticky={true}
            //style={{ maxWidth: 1400, margin: "auto" }}
          />
        </div>
      </div>
    </>
  );
};

export default Sales;
