import { Form, Input, Modal, Radio, Divider, Row, Col } from "antd";
import { useRecoilState } from "recoil";
import DatePicker from "../../../components/DatePicker";
import { isOpenState } from "../../../state/atoms/isOpen";
import TableInTemplate from "./TableInTemplate";
import { useFreeeTemplates } from "../api";

const EditTemplate = () => {
  const form = Form.useFormInstance();

  const [modalIsOpen, setIsOpen] = useRecoilState(isOpenState("freeeTemplateEditModal"));
  const { freeeTemplates, editTemplate } = useFreeeTemplates();

  const handleFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(888, values);
        await editTemplate(values, freeeTemplates.company.companyId);
        setIsOpen(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <Modal
        title="テンプレートを編集"
        width="95%"
        visible={modalIsOpen}
        okText="登録"
        cancelText="戻る"
        onOk={() => handleFinish()}
        onCancel={() => setIsOpen(false)}
        getContainer={false}
      >
        <Form.Item name="templateId" hidden>
          <Input />
        </Form.Item>
        <div>テンプレート名</div>
        <Form.Item
          name="templateName"
          rules={[
            {
              required: true,
              message: "必須項目です",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row type="flex" justify="start">
          <Col span={6}>
            <Form.Item
              label="発生日"
              name="issueDate"
              // rules={[
              //   {
              //     required: true,
              //     message: "必須項目です",
              //   },
              // ]}
            >
              <DatePicker disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="adjustment" initialValue={false}>
              <Radio.Group>
                <Radio value={false}>日常仕訳</Radio>
                <Radio value={true}>決算整理仕訳</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="between" style={{ backgroundColor: "lightgray", padding: "10px" }}>
          <Col span={6}>
            <div>借方勘定科目</div>
            <div>取引先・品目・部門・メモタグ</div>
          </Col>
          <Col span={4}>
            <div>借方金額</div>
            <div>借方税区分・税額</div>
          </Col>
          <Col span={6}>
            <div>貸方勘定科目</div>
            <div>取引先・品目・部門・メモタグ</div>
          </Col>
          <Col span={4}>
            <div>貸方金額</div>
            <div>貸方税区分・税額</div>
          </Col>
          <Col span={4}>備考</Col>
        </Row>
        <TableInTemplate templateSetting={true} />
      </Modal>
    </>
  );
};
export default EditTemplate;
