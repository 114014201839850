import { Form, Space, Spin, Table, Tag, Typography, Popconfirm, message } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { useSetRecoilState } from "recoil";
import CustomEmpty from "../../../components/CustomEmpty";
import { editAddressState } from "../../../state/atoms/editAddressAtom";
import { isOpenState } from "../../../state/atoms/isOpen";
import styled from "styled-components";
import ChainList from "../../../constant/chainList";
import { useContractAddress } from "../api";

const { Text } = Typography;

const StyledDisabled = styled.div`
  color: #b5b5b5;
  pointer-events: none;
`;

const SpinBackground = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`;

const ContractAddressList = () => {
  const [form] = Form.useForm();
  const { contractAddressList, isLoadingContractAddressList, deleteContractAddress } = useContractAddress();
  const setIsOpen = useSetRecoilState(isOpenState("contractAddressEditModal"));
  const setEditContractAddressInfo = useSetRecoilState(editAddressState("contractAddress"));

  if (isLoadingContractAddressList)
    // TODO: loading 画面は共有コンポーネントとして作成した方が良い
    return (
      <SpinBackground>
        <Spin style={{ aline: "center" }} />
      </SpinBackground>
    );

  const columns = [
    {
      title: "コントラクトアドレス",
      dataIndex: "contractAddress",
      key: "contractAddress",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {record.invalid ? <StyledDisabled>{text}</StyledDisabled> : text}
        </div>
      ),
    },
    {
      title: "表示名",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          <Tag color={record.themeColor} key={text}>
            {text}
          </Tag>
        </div>
      ),
    },
    {
      title: "チェーン",
      dataIndex: "chainId",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {record.invalid ? <StyledDisabled>{ChainList[text].name}</StyledDisabled> : ChainList[text].name}
        </div>
      ),
    },
    {
      title: "メモ",
      dataIndex: "memo",
      key: "memo",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {record.invalid ? <StyledDisabled>{text}</StyledDisabled> : text}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: 70,
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              setEditContractAddressInfo({
                contractAddress: record.contractAddress,
                name: record.name,
                memo: record.memo,
                methodNamesAggregated: record.methodNamesAggregated,
                themeColor: record.themeColor,
                chainId: record.chainId,
              });
              setIsOpen(true);
            }}
          >
            <EditOutlined />
          </Typography.Link>
        );
      },
    },
    {
      title: "",
      dataIndex: "settings",
      key: "settings",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="本当に削除しますか？"
            onConfirm={async (e) => {
              e.preventDefault();
              const result = deleteContractAddress(record);
              result ? message.success("アドレスを削除しました") : message.error("アドレスが削除できませんでした");
            }}
            okText="はい"
            cancelText="いいえ"
          >
            <a
              href="/#"
              key="list-invalid"
              onClick={async (e) => {
                e.preventDefault();
              }}
            >
              {record.invalid ? "削除する" : <Text type="danger">削除する</Text>} {/* TODO: invalidを消す */}
            </a>
          </Popconfirm>
          {/* <a key="list-more">削除</a> */}
        </Space>
      ),
    },
  ];

  const contractAddressListAddedKey = contractAddressList.map((ca) => {
    return {
      ...ca,
      key: ca.contractAddress,
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          locale={{
            emptyText: <CustomEmpty />,
          }}
          columns={columns}
          dataSource={contractAddressListAddedKey}
        />
      </Form>
    </>
  );
};

export default ContractAddressList;
