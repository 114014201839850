import { useSetRecoilState } from "recoil";
import { Button } from "antd";
import RegisterContractAddress from "../components/RegisterContractAddress";
import EditContractAddress from "../components/EditContractAddress";
import ContractAddressList from "../components/ContractAddressList";
import { isOpenState } from "../../../state/atoms/isOpen";

const ContractAddress = () => {
  const setIsOpen = useSetRecoilState(isOpenState("contractAddressRegistrationModal"));
  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        新規登録
      </Button>
      <RegisterContractAddress />
      <EditContractAddress />
      <ContractAddressList />
    </>
  );
};

export default ContractAddress;
