export const addUnitYen = (v) => {
  if (v === 0) {
    return "-";
  }
  return Number(Math.round(v)).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " 円";
};

export const addUnitEth = (v) => {
  if (v === 0) {
    return "-";
  }
  return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " ETH";
};

export const addUnitMatic = (v) => {
  if (v === 0) {
    return "-";
  }
  return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " MATIC";
};

export const addUnitCrypto = (v, chainId) => {
  if (v === 0) {
    return "-";
  }
  switch (chainId) {
    case 1:
      return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " ETH";
    case 137:
      return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " MATIC";
    case 592:
      return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " ASTR";
    default:
      return v;
  }
};

export const addUnitToken = (v, symbol) => {
  if (v === 0) {
    return "-";
  }
  switch (symbol) {
    case "WETH":
      return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " ETH";
    case "USDC":
      return Number(v).toLocaleString(undefined, { maximumFractionDigits: 3 }) + " USD";
    default:
      return v;
  }
};

export const displayTxType = (txType) => {
  switch (txType) {
    case "txlist":
      return "Transactions";
    case "txlistinternal":
      return "Internal Txns";
    case "tokentx":
      return "ERC-20 Token Txns";
    case "tokennfttx":
      return "ERC-721 Token Txns";
    default:
      return txType;
  }
};
