import TemplateList from "../components/TemplateList";

const Freee = () => {
  return (
    <>
      <TemplateList />
    </>
  );
};

export default Freee;
