import { Descriptions, Typography, Button } from "antd";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { isOpenState } from "../../../state/atoms/isOpen";
import { projectListState } from "../../../state/atoms/projectListAtom";
const { Text } = Typography;

const ProjectDetail = () => {
  const { projectId } = useParams();
  const projectList = useRecoilValue(projectListState);
  const setIsOpen = useSetRecoilState(isOpenState("projectRegistrationModal"));
  const currentProject = projectList.projects.find((project) => project.projectId === projectId);

  return (
    <Descriptions
      title={currentProject.name}
      extra={
        <Button type="primary" onClick={() => setIsOpen(true)}>
          編集
        </Button>
      }
      style={{ maxWidth: "800px" }}
    >
      <Descriptions.Item label="開始月" span={3}>
        {currentProject.beginningMonth}月
      </Descriptions.Item>
      <Descriptions.Item label="プロジェクト概要" span={3}>
        {currentProject.description || <Text disabled>未登録</Text>}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ProjectDetail;
