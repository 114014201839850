import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getUnixTime, parseISO } from "date-fns";
import { searchPeriodState } from "../../../state/atoms/searchPeriodAtom";

export const useSearchPeriod = () => {
  const { projectId } = useParams();

  const [searchPeriod, setSearchPeriod] = useRecoilState(searchPeriodState(projectId));

  // since, until は "yyyy-MM-dd" とする
  const updateSearchPeriod = ({ since, until }) => {
    const now = new Date();

    const sinceUnixTime = getUnixTime(parseISO(since, "yyyy-MM-dd", now));
    const newSince = isNaN(sinceUnixTime) ? searchPeriod.since : since;
    const untilUnixTime = getUnixTime(parseISO(until, "yyyy-MM-dd", now));
    const newUntil = isNaN(untilUnixTime) ? searchPeriod.until : until;

    setSearchPeriod({
      since: newSince,
      until: newUntil,
    });
  };

  return { searchPeriod, updateSearchPeriod };
};
