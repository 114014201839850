import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { axiosClient } from "../../../common/axiosClient";
import { freeeTemplatesState } from "../../../state/atoms/freeeTemplateAtom";

export const useAuthFreee = () => {
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState("loading");

  const useAuth = () => {
    useEffect(() => {
      try {
        (async () => {
          await axiosClient.post(`/freee/auth?code=${searchParams.get("code")}`);
        })();
        setIsSuccess("success");
      } catch (e) {
        console.error(e);
        setIsSuccess("error");
      }
    }, []);
  };

  return { isSuccess, useAuth };
};

export const deleteToken = async () => {
  await axiosClient.delete(`/freee/token`);
};

export const useTransferMemo = () => {
  const { projectId } = useParams();

  const registerTransferMemo = async (postObject) => {
    postObject.issueDate = format(postObject.issueDate, "yyyy-MM-dd");

    const result = await axiosClient.post(`/${projectId}/freee/transferMemo`, postObject);
    return result.data;
  };

  return { registerTransferMemo };
};

const convertFromDDBToForm = (templatesFromDDB) => {
  const templatesFormFormat = [];
  templatesFromDDB.map((t) => {
    const details = t.details.map((detail) => {
      const formattedAggregationAddress = detail.aggregationAddress.reduce((result, ag) => {
        result[ag.walletAddress] = ag.contractAddresses;
        return result;
      }, {});

      return {
        ...detail,
        aggregationAddress: formattedAggregationAddress,
      };
    });
    templatesFormFormat.push({ ...t, details });
    return templatesFormFormat;
  });
  return templatesFormFormat;
};

// walletAddress: contractAddress[] を json 形式に変換
// credit, debit を1つの配列にまとめる
const convertFromFormToDDB = (templateFromForm) => {
  const formattedAccountItems = templateFromForm.details.map((detail) => {
    const formattedAggregationAddress = Object.entries(detail.aggregationAddress).map(([key, value]) => {
      if (value === undefined) return;
      return {
        walletAddress: key,
        contractAddresses: value,
      };
    });

    return {
      ...detail,
      aggregationAddress: formattedAggregationAddress.filter((item) => item !== undefined),
    };
  });

  return {
    templateId: templateFromForm.templateId,
    templateName: templateFromForm.templateName,
    adjustment: templateFromForm.adjustment,
    txnNumber: templateFromForm.txnNumber,
    details: formattedAccountItems,
    description: templateFromForm.description,
  };
};

export const useFreeeTemplates = () => {
  const { projectId } = useParams();
  const [freeeTemplates, setFreeeTemplates] = useRecoilState(freeeTemplatesState(projectId));
  const [isFreeeTemplatesLoading, setIsFreeeTemplatesLoading] = useState(false);

  const getTemplates = async () => {
    setIsFreeeTemplatesLoading(true);
    const response = await axiosClient.get(`/${projectId}/freee/templates`);
    const freeeTemplatesInForm = convertFromDDBToForm(response.data.templates);
    setFreeeTemplates({ ...response.data, templates: freeeTemplatesInForm });
    setIsFreeeTemplatesLoading(false);
  };

  const editTemplate = async (template, freeeCompanyId) => {
    setIsFreeeTemplatesLoading(true);
    await axiosClient.post(`/${projectId}/${freeeCompanyId}/freee/templates`, convertFromFormToDDB(template));
    setFreeeTemplates((prev) => {
      return {
        ...prev,
        templates: [...prev.templates.filter((t) => t.templateId !== template.templateId), template],
      };
    });
    setIsFreeeTemplatesLoading(false);
  };

  const deleteTemplate = async (templateId, freeeCompanyId) => {
    setIsFreeeTemplatesLoading(true);
    await axiosClient.delete(`/${projectId}/${freeeCompanyId}/freee/templates/${templateId}`);
    setFreeeTemplates((prev) => {
      return { ...prev, templates: [...prev.templates.filter((t) => t.templateId !== templateId)] };
    });
    setIsFreeeTemplatesLoading(false);
  };

  return {
    freeeTemplates,
    isFreeeTemplatesLoading,
    getTemplates,
    deleteTemplate,
    editTemplate,
  };
};
