import SearchCondition from "../components/SearchCondition";
import TransactionTable from "../components/TransactionTable";

const Transaction = () => {
  return (
    <>
      <SearchCondition />
      <TransactionTable />
    </>
  );
};

export default Transaction;
