import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { notification } from "antd";
import Background from "./layout/Layout";
import SettingsBackground from "./layout/SettingsLayout";
import Dashboard from "./features/sales/pages/Dashboard";
import Transaction from "./features/transaction/pages/Transaction";
import WalletAddress from "./features/walletAddress/pages/WalletAddress";
import ContractAddress from "./features/contractAddress/pages/ContractAddress";
import SyncData from "./features/syncData/pages/SyncData";
import Project from "./features/project/pages/Project";
import NotFound from "./components/NotFound";
import User from "./features/user/pages/User";
import ProjectList from "./features/project/pages/ProjectList";
import Freee from "./features/freee/pages/Freee";
import FreeeSyncCompletion from "./features/freee/pages/FreeeSyncCompletion";
import ProjectBackground from "./layout/ProjectLayout";

export const path = {
  // companyごとの画面
  projectList: "/",
  userList: "/settings/userList",
  // projectごとの画面
  dashboard: "/:projectId/dashboard",
  transaction: "/:projectId/transaction",
  walletAddressList: "/:projectId/settings/walletAddressList",
  contractAddressList: "/:projectId/settings/contractAddressList",
  freee: "/:projectId/settings/freee",
  syncData: "/:projectId/settings/syncData",
  project: "/:projectId/settings/project",
  freeeSyncCompletion: "/freeeSyncCompletion",
};

function App() {
  notification.config({
    duration: 3,
  });

  return (
    <div className="App" style={{ backgroundColor: "#fafafa" }}>
      <Router>
        <Routes>
          <Route element={<ProjectBackground />}>
            <Route path={path.projectList} element={<ProjectList />} />
            <Route path={path.userList} element={<User />} />
          </Route>
          <Route element={<Background />}>
            <Route path={path.dashboard} element={<Dashboard />} />
            <Route path={path.transaction} element={<Transaction />} />
          </Route>
          <Route element={<SettingsBackground />}>
            <Route path={path.walletAddressList} element={<WalletAddress />} />
            <Route path={path.contractAddressList} element={<ContractAddress />} />
            <Route path={path.freee} element={<Freee />} />
            <Route path={path.syncData} element={<SyncData />} />
            <Route path={path.project} element={<Project />} />
            <Route path={path.userList} element={<User />} />
          </Route>
          <Route path={path.freeeSyncCompletion} element={<FreeeSyncCompletion />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
