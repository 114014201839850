import { Alert, Select, Spin, Tag, Typography, Tooltip as AntdTooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { Link, useParams } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { useFetchSalesSummary } from "../api";
import { useContractAddress } from "../../contractAddress/api";
import { selectedContractInMethodCallCountState } from "../../../state/atoms/selectedContractInMethodCallCount";
import { generateHlsHueFromString } from "../../../common/util";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const { Title: AntdTitle } = Typography;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

// オプションやデータを動的に変更する場合
// https://www.chartjs.org/docs/latest/developers/api.html

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

const ContractAddressNotFound = () => (
  <div
    style={{
      backgroundColor: "white",
      padding: 20,
      borderRadius: "16px",
      boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.15)",
    }}
  >
    <FlexBetween>
      <AntdTitle level={5}>
        メソッド実行回数
        <AntdTooltip title="同期済みのトランザクションのみ集計対象">
          <InfoCircleOutlined />
        </AntdTooltip>
      </AntdTitle>
    </FlexBetween>
    <Bar options={options} data={{ labels: [], datasets: [] }} />
  </div>
);

const MethodCallCountBarChart = () => {
  const { projectId } = useParams();
  const { summary, isLoadingSummary } = useFetchSalesSummary();
  const { contractAddressList, isLoadingContractAddressList } = useContractAddress();

  const [selectedContractInMethodCallCount, setSelectedContractInMethodCallCount] = useRecoilState(
    selectedContractInMethodCallCountState(projectId)
  );

  if (isLoadingSummary || isLoadingContractAddressList) {
    return (
      <div style={{ backgroundColor: "white", padding: 10 }}>
        <Spin />
      </div>
    );
  }

  if (contractAddressList.length === 0) return <ContractAddressNotFound />;

  const handleChange = (value) => {
    setSelectedContractInMethodCallCount(value);
  };

  const optionsContractAddress = contractAddressList.map((ca) => {
    return {
      label: ca.name,
      value: ca.contractAddress,
    };
  });

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const address = contractAddressList.find((ca) => ca.contractAddress === value);

    return (
      <Tag
        color={address === undefined ? "gray" : address.themeColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const today = new Date();
  const threeMonthsAgo = new Date().setMonth(today.getMonth() - 12);

  const labels = summary.filter((s) => threeMonthsAgo < new Date(s.key) && new Date(s.key) < today).map((s) => s.key);

  const barThickness = 10; // TODO: データ数に応じて調整するように

  const datasets = [];
  labels.map((label) => {
    const targetSalesSummary = summary.find((s) => s.key === label);

    // contractAddress ごとに集計する
    const methodCallCountHash = targetSalesSummary.methodCallCount
      .filter((mc) => selectedContractInMethodCallCount.includes(mc.contractAddress))
      .reduce((map, item) => {
        const key = item.methodName;
        if (map.has(key)) {
          map.get(key).count += item.count;
        } else {
          map.set(key, { methodName: item.methodName, count: item.count });
        }
        return map;
      }, new Map());

    // chartjs で表示するよう変形
    Array.from(methodCallCountHash.values()).map((mc) => {
      const targetDatasetIndex = datasets.findIndex((dataset) => dataset.label === mc.methodName);
      if (targetDatasetIndex === -1) {
        datasets.push({
          label: mc.methodName,
          data: [mc.count],
          backgroundColor: `hsl(${generateHlsHueFromString(mc.methodName)}, 80%, 64%)`,
          barThickness: barThickness,
          maxBarThickness: barThickness,
        });
      } else {
        datasets[targetDatasetIndex].data.push(mc.count);
      }
    });
  });

  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: "16px",
        boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.15)",
        position: "relative",
      }}
    >
      <FlexBetween>
        <AntdTitle level={5}>
          メソッド実行回数
          <AntdTooltip title="同期済みのトランザクションのみを対象として集計">
            <InfoCircleOutlined />
          </AntdTooltip>
        </AntdTitle>
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "50%" }}
          placeholder="Please select"
          defaultValue={selectedContractInMethodCallCount}
          onChange={handleChange}
          maxTagCount="responsive"
          tagRender={tagRender}
          options={optionsContractAddress}
        />
      </FlexBetween>
      <Bar options={options} data={data} />
      {data.datasets.length === 0 && (
        <Alert
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          message={
            <>
              <Link to={`/${projectId}/settings/contractAddressList`}>コントラクトアドレス設定</Link>
              <span>で集計したいメソッドを設定してください</span>
            </>
          }
          type="warning"
        />
      )}
    </div>
  );
};

export default MethodCallCountBarChart;
