import { useState, useEffect } from "react";
import { Form, Input, Modal, Spin } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { CirclePicker } from "react-color";
import { useWalletAddress } from "../api";
import { editAddressState } from "../../../state/atoms/editAddressAtom";
import { isOpenState } from "../../../state/atoms/isOpen";

const layout = {
  labelCol: {
    offset: 1,
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const EditWalletAddress = () => {
  const [form] = Form.useForm();

  const { updateWalletAddress } = useWalletAddress();

  const [themeColorHex, setThemeColorHex] = useState("#f44336");
  const editWalletAddressInfo = useRecoilValue(editAddressState("walletAddress"));
  const [modalIsOpen, setIsOpen] = useRecoilState(isOpenState("walletAddressEditModal"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(editWalletAddressInfo);
    setThemeColorHex(editWalletAddressInfo.themeColor);
  }, [form, editWalletAddressInfo]);

  const onRegisterWalletAddress = async () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        await updateWalletAddress(values);

        setLoading(false);
        setIsOpen(false);
        form.resetFields();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        title="ウォレットアドレスの情報を編集"
        width={1000}
        visible={modalIsOpen}
        okText="更新"
        cancelText="戻る"
        onOk={onRegisterWalletAddress}
        onCancel={() => setIsOpen(false)}
      >
        <Spin spinning={loading}>
          <Form {...layout} form={form} name="control-hooks">
            <Form.Item name="walletAddress" label="アドレス" initialValue={editWalletAddressInfo.walletAddress}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="name"
              label="表示名"
              initialValue={editWalletAddressInfo.name}
              rules={[
                {
                  required: true,
                  message: "好きな名前を入力してください",
                },
              ]}
            >
              <Input placeholder="○○用" />
            </Form.Item>

            <Form.Item name="memo" label="メモ" initialValue={editWalletAddressInfo.memo}>
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="themeColor"
              label="テーマカラー"
              rules={[
                {
                  required: true,
                  message: "好きな色を選択してください",
                },
              ]}
              getValueFromEvent={(args) => args.hex}
              initialValue={themeColorHex}
            >
              <CirclePicker width="690" color={themeColorHex} onChange={(color) => setThemeColorHex(color.hex)} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default EditWalletAddress;
