import { useState } from "react";
import { Form, Input, Spin, Modal, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { CirclePicker } from "react-color";
import { ethers } from "ethers";
import { useContractAddress, useContractMetaData } from "../api";
import NftMetaData from "./NftMetaData";
import { isOpenState } from "../../../state/atoms/isOpen";

const { Option } = Select;

const layout = {
  labelCol: {
    offset: 1,
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const RegisterContractAddress = () => {
  const [form] = Form.useForm();

  const chainId = Form.useWatch("chainId", form);
  const contractAddress = Form.useWatch("contractAddress", form);

  const { contractAddressList, registerContractAddress } = useContractAddress();

  const { fetchContractMetaData } = useContractMetaData();
  const { contractMetaData, loadingContractMetaData } = fetchContractMetaData(chainId, contractAddress);

  const [themeColorHex, setThemeColorHex] = useState("#f44336");
  const [modalIsOpen, setIsOpen] = useRecoilState(isOpenState("contractAddressRegistrationModal"));

  const onRegisterContractAddress = async () => {
    form
      .validateFields()
      .then(async (values) => {
        await registerContractAddress(values);
        setIsOpen(false);
        form.resetFields();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <Modal
        title="新しいコントラクトアドレスを登録"
        width={1000}
        visible={modalIsOpen}
        okText="登録"
        cancelText="戻る"
        onOk={onRegisterContractAddress}
        onCancel={() => setIsOpen(false)}
      >
        <Spin spinning={false}>
          <Form {...layout} form={form} name="control-hooks" initialValues={{ chainId: 1 }}>
            <Form.Item name="chainId" label="チェーン">
              <Select
                defaultValue={{
                  value: "1",
                  label: "Ethereum",
                }}
                initialValue="1"
                style={{ width: 120 }}
              >
                <Option value={1}>Ethereum</Option>
                <Option value={137}>Polygon</Option>
                <Option value={592}>Astar</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="contractAddress"
              label="アドレス"
              rules={[
                {
                  required: true,
                  message: "登録したいコントラクトアドレスを入力してください",
                },
                {
                  message: "このアドレスは既に登録されています",
                  validator: (_, value) => {
                    if (
                      typeof value !== "undefined" &&
                      value !== "" &&
                      contractAddressList.map((ca) => ca.contractAddress.toLowerCase()).includes(value.toLowerCase())
                    ) {
                      return Promise.reject(new Error("This address has already been registered."));
                    }
                    return Promise.resolve();
                  },
                },
                {
                  message: "このアドレスは有効ではありません",
                  validator: (_, value) => {
                    if (typeof value !== "undefined" && value !== "" && !ethers.utils.isAddress(value)) {
                      return Promise.reject(new Error("This address is invalid."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="0xAb..." />
            </Form.Item>
            <Form.Item name="metaData" label="メタデータ">
              {loadingContractMetaData ? <Spin /> : <NftMetaData {...contractMetaData} />}
            </Form.Item>
            <Form.Item
              name="name"
              label="表示名"
              rules={[
                {
                  required: true,
                  message: "好きな名前を入力してください",
                },
              ]}
            >
              <Input placeholder="○○用" />
            </Form.Item>

            <Form.Item
              name="methodNamesAggregated"
              label={
                <div>
                  メソッド{" "}
                  <Tooltip title="ここで指定したメソッドの呼び出し回数を月次で集計して表示します。大文字と小文字の区別はありません。">
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
            >
              <Input placeholder="transferfrom,approve" />
            </Form.Item>

            <Form.Item name="memo" label="メモ">
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="themeColor"
              label="テーマカラー"
              rules={[
                {
                  required: true,
                  message: "好きな色を選択してください",
                },
              ]}
              getValueFromEvent={(args) => args.hex}
              initialValue={themeColorHex}
            >
              <CirclePicker width="690" color={themeColorHex} onChange={(color) => setThemeColorHex(color.hex)} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default RegisterContractAddress;
