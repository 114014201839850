import { axiosClient } from "../../../common/axiosClient";
import { notifyError } from "../../../common/notify";

export const createProject = async (name, beginningMonth, description) => {
  const postObject = {
    name,
    beginningMonth,
    description,
  };
  try {
    const result = await axiosClient.post(`/project/register`, postObject);
    return result.data;
  } catch (e) {
    console.error(e);
    notifyError(e);
  }
};

export const fetchProjects = async () => {
  try {
    const result = await axiosClient.get(`/project`);
    return result.data;
  } catch (e) {
    console.error(e);
    notifyError(e);
  }
};

export const updateProject = async (projectId, name, beginningMonth, description) => {
  const postObject = {
    name,
    beginningMonth,
    description,
  };
  try {
    const result = await axiosClient.post(`/project/${projectId}`, postObject);
    return result.data;
  } catch (e) {
    console.error(e);
    notifyError(e);
  }
};
