import SyncDataList from "../components/SyncDataList";

const SyncData = () => {
  return (
    <>
      <SyncDataList />
    </>
  );
};

export default SyncData;
