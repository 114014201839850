import { Card, Row, Col, Tag } from "antd";
import { format } from "date-fns";
import styled from "styled-components";
import { useFetchSalesSummary } from "../api";

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTag = styled(Tag)`
  aline: right;
  height: 60%;
`;

const ShadowCard = styled(Card)`
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
`;

const Tile = (props) => {
  const { month, title, thisMonthValue, previousMonthValue, loading } = props;

  const monthlyRatio = Math.round((100 * thisMonthValue) / previousMonthValue);

  let tag;
  if (thisMonthValue === previousMonthValue) {
    tag = <StyledTag>±0%</StyledTag>;
  } else if (thisMonthValue > previousMonthValue) {
    tag = <StyledTag color="red">+{monthlyRatio - 100 > 1000 ? "1000%以上" : `${monthlyRatio - 100}%`}</StyledTag>;
  } else {
    tag = <StyledTag color="green">-{100 - monthlyRatio}%</StyledTag>;
  }

  return (
    <ShadowCard bordered={false} loading={loading}>
      <div style={{ paddingLeft: 10 }}>
        <p style={{ textAlign: "left", color: "gray" }}>
          {month}月の{title}
        </p>
        <h1 style={{ textAlign: "left" }}>
          <FlexBetween>
            ¥{Math.round(thisMonthValue).toLocaleString()} {tag}
          </FlexBetween>
        </h1>
        <h3 style={{ textAlign: "left" }}>
          <span style={{ color: "gray" }}>{month - 1 === 0 ? 12 : month - 1}月:</span> ¥
          {Math.round(previousMonthValue).toLocaleString()}
        </h3>
      </div>
    </ShadowCard>
  );
};

const NumberDisplay = () => {
  const { summary, isLoadingSummary } = useFetchSalesSummary();

  const today = new Date();
  let data;

  if (isLoadingSummary) {
    data = [
      {
        title: "売上額",
      },
      {
        title: "入金額",
      },
      {
        title: "出金額",
      },
      {
        title: "Gas代",
      },
    ];
  } else {
    // データがない場合は何も表示しない
    if (summary.length === 0) return <div></div>;

    const thisMonthData = summary.find((s) => s.key === `${format(today, "yyyy-MM")}`);

    const monthBefore = new Date().setMonth(today.getMonth() - 1);
    const previousMonthData = summary.find((s) => s.key === `${format(monthBefore, "yyyy-MM")}`);

    data = [
      {
        title: "売上額",
        thisMonthValue: thisMonthData === undefined ? 0 : thisMonthData.salesValueJpy,
        previousMonthValue: previousMonthData?.salesValueJpy,
      },
      {
        title: "入金額",
        thisMonthValue: thisMonthData === undefined ? 0 : thisMonthData.depositsJpy,
        previousMonthValue: previousMonthData?.depositsJpy,
      },
      {
        title: "出金額",
        thisMonthValue: thisMonthData === undefined ? 0 : thisMonthData.withdrawalsExceptGasJpy,
        previousMonthValue: previousMonthData?.withdrawalsExceptGasJpy,
      },
      {
        title: "Gas代",
        thisMonthValue: thisMonthData === undefined ? 0 : thisMonthData.gasJpy,
        previousMonthValue: previousMonthData?.gasJpy,
      },
    ];
  }

  return (
    <Row justify="space-around" align="middle">
      {data.map((d) => (
        <Col key={d.title} xs={24} md={12} lg={6} align="middle" style={{ padding: 10 }}>
          <Tile
            title={d.title}
            thisMonthValue={d.thisMonthValue}
            previousMonthValue={d.previousMonthValue}
            month={today.getMonth() + 1}
            loading={isLoadingSummary}
          />
        </Col>
      ))}
    </Row>
  );
};

export default NumberDisplay;
