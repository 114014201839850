// Etherscan, Polygonscan の base url を返す
export const getBaseUrlOfScan = (chainId) => {
  if (chainId === 1) {
    return "https://etherscan.io/"; // TODO: constant ファイルにまとめる
  } else if (chainId === 137) {
    return "https://polygonscan.com/";
  } else if (chainId === 592) {
    return "https://astar.subscan.io/";
  }
};

// HSL の色相を入力文字列から生成する
// 参考: https://zenn.dev/rabee/articles/javascript-string-to-number-to-color
export const generateHlsHueFromString = (s) => {
  const n = Array.from(s)
    .map((ch) => ch.charCodeAt(0))
    .reduce((a, b) => a + b);

  return (n * n) % 360;
};
