import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosClient } from "../../../common/axiosClient";
import { notifyError } from "../../../common/notify";

export const useFetchCountTxs = () => {
  const { projectId } = useParams();
  const [countTxs, setCountTxs] = useState([]);
  const [loadingCountTxs, setLoadingCountTxs] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoadingCountTxs(true);
        const response = await axiosClient.get(`/${projectId}/countTransactions`);
        setCountTxs(response.data);
      } catch (e) {
        console.error(e);
        notifyError("データの取得に失敗しました");
      } finally {
        setLoadingCountTxs(false);
      }
    })();
  }, []);

  return { countTxs, loadingCountTxs };
};
