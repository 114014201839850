import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import RegisterProject from "../components/RegisterProject";
import ProjectDetail from "../components/ProjectDetail";
import { projectListState } from "../../../state/atoms/projectListAtom";

const Project = () => {
  const { projectId } = useParams();
  const projectList = useRecoilValue(projectListState);
  const currentProject = projectList.projects.find((project) => project.projectId === projectId);

  return (
    <>
      <RegisterProject edit={true} title="プロジェクト情報を編集" okText="更新" formData={currentProject} />
      <ProjectDetail />
    </>
  );
};

export default Project;
