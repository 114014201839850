import { Select } from "antd";

const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const SearchSelect = (props) => (
  <Select
    {...props}
    showSearch
    optionFilterProp="children"
    filterOption={filterOption}
    options={props.options.map((option) => {
      return { value: option.id, label: option.name };
    })}
  />
);

export default SearchSelect;
