import { Layout, Menu, Row, Col } from "antd";
import { Link, Outlet, useParams } from "react-router-dom";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SettingsBackground = () => {
  const { projectId } = useParams();
  let location = useLocation();

  const items = [
    getItem(
      <Link key="1" to={`/${projectId}/settings/walletAddressList`}>
        ウォレットアドレス
      </Link>,
      `/${projectId}/settings/walletAddressList`
    ),
    getItem(
      <Link key="2" to={`/${projectId}/settings/contractAddressList`}>
        コントラクトアドレス
      </Link>,
      `/${projectId}/settings/contractAddressList`
    ),
    getItem(
      <Link key="3" to={`/${projectId}/settings/freee`}>
        freee 連携
      </Link>,
      `/${projectId}/settings/freee`
    ),
    getItem(
      <Link key="4" to={`/${projectId}/settings/syncData`}>
        データ同期状況
      </Link>,
      `/${projectId}/settings/syncData`
    ),
    getItem(
      <Link key="5" to={`/${projectId}/settings/project`}>
        プロジェクト設定
      </Link>,
      `/${projectId}/settings/project`
    ),
  ];

  return (
    <div>
      <Header />
      <Content className="site-layout">
        <Row>
          <Col span={4} style={{ display: "flex" }}>
            <Menu selectedKeys={[location.pathname]} mode="inline" items={items} style={{ minHeight: "90vh" }} />
          </Col>
          <Col span={20}>
            <div style={{ padding: "0 30px", marginTop: 16 }}>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default SettingsBackground;
