import { Row, Col } from "antd";
import TransactionCountBarChart from "../components/TransactionCountBarChart";
import MethodCallCountBarChart from "../components/MethodCallCountBarChart";
import SalesStackedBarChart from "../components/SalesStackedBarChart";
import NumberDisplay from "../components/NumberDisplay";
import Balance from "../../walletAddress/components/Balance";
import Sales from "../components/Sales";
import FreeeSync from "../components/freeeSync";

const Dashboard = () => {
  return (
    <>
      <Balance />
      <NumberDisplay />
      <Row justify="space-around" align="middle">
        <Col xs={24} md={12} align="middle" style={{ padding: 10 }}>
          <TransactionCountBarChart />
        </Col>
        <Col xs={24} md={12} align="middle" style={{ padding: 10 }}>
          <MethodCallCountBarChart />
        </Col>
      </Row>

      <Sales />
      <FreeeSync />
      <div style={{ height: "40px" }}></div>
    </>
  );
};

export default Dashboard;
