import React from "react";
import ReactDOM from "react-dom/client";
import { Spin } from "antd";
import App from "./App";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator, Image, View, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { RecoilURLSyncJSON } from "recoil-sync";
import { I18n } from "aws-amplify";
import Logo from "./logo2.svg";

const root = ReactDOM.createRoot(document.getElementById("root"));

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
});

const dict = {
  ja: {
    Username: "メールアドレス",
    "Enter your Username": "example@cc.com",
    Password: "パスワード",
    "Enter your Password": "",
    "Sign in": "ログイン",
    "Reset Password": "パスワード再発行",
    "Send code": "認証コードを送る",
    "Back to Sign In": "ログインページに戻る",
    "Forgot your password?": "パスワードを忘れた場合",
    "Reset password": "パスワードをリセット",
    "No account?": "アカウントを持っていない場合",
    Code: "認証コード",
    "Code *": "認証コード",
    "New Password": "新しいパスワード",
    "Confirm Password": "新しいパスワード（確認）",
    "": "",
    Submit: "再設定",
    "Resend Code": "認証コードを再送信する",
    "Family Name": "名字",
    "Given Name": "名前",
    "Change Password": "登録",
  },
};

// TODO: エラー文も日本語化する。参考：https://zenn.dev/yuji/articles/4d910df6d2d08c
I18n.putVocabularies(dict);
I18n.setLanguage("ja");

const components = {
  Header() {
    return (
      <>
        <div style={{ height: "40px", backgroundColor: "white" }}></div>
        <View textAlign="center" backgroundColor="white">
          <Image alt="Amplify logo" src={Logo} maxWidth="120px" />
        </View>
      </>
    );
  },
  Footer() {
    return (
      <View textAlign="center" backgroundColor="white">
        <div>&copy; All Rights Reserved</div>
      </View>
    );
  },
  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/document/d/1rVqbpbr-DgtGMTzxnws7b9oHBoFI4MDkPd1NDSJoYJk/edit"
            >
              プライバシーポリシー
            </a>
            及び
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/document/d/195X7Rh0n6bsPLAlWK_q7W1u2yHjrWnQkilRY75Jhnks/edit"
            >
              利用規約
            </a>
            に同意してログイン
          </div>
          <div style={{ height: 10 }}></div>
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            パスワードを忘れた場合はこちら
          </Button>
        </View>
      );
    },
  },
};

root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <RecoilURLSyncJSON location={{ part: "queryParams" }}>
      <Authenticator variation="modal" components={components}>
        <React.Suspense
          fallback={
            <div style={{ position: "relative", width: "100%", minHeight: "100vh" }}>
              <Spin tip="Loading" size="large" style={{ position: "absolute", top: "50%", left: "50%" }} />
            </div>
          }
        >
          <App />
        </React.Suspense>
      </Authenticator>
    </RecoilURLSyncJSON>
  </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
