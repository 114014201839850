import { Empty } from "antd";

const CustomEmpty = ({ description = "データがありません" }) => (
  <Empty
    imageStyle={{
      height: 60,
    }}
    description={<span>{description}</span>}
  />
);

export default CustomEmpty;
