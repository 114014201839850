import { Button, Card, Form, Col, Row, Tooltip, Tag, List, Spin, Typography, Divider } from "antd";
import { useSetRecoilState } from "recoil";
import { isOpenState } from "../../../state/atoms/isOpen";
import { useWalletAddress } from "../../walletAddress/api";
import { useContractAddress } from "../../contractAddress/api";
import { useFreeeTemplates, deleteToken } from "../api";
import EditTemplate from "../components/EditTemplate";
import DemandSignInFreeeModal from "./DemandSignInFreeeModal";
import { useEffect } from "react";

const { Text, Title } = Typography;

const tagRender = (address) => {
  return (
    <Tag
      color={address === undefined ? "gray" : address.themeColor}
      style={{
        marginRight: 3,
      }}
    >
      <Tooltip title={address.walletAddress || address.contractAddress}>{address.name}</Tooltip>
    </Tag>
  );
};

const TemplateList = () => {
  const { walletAddressList, isLoadingWalletAddressList } = useWalletAddress();
  const { contractAddressList, isLoadingContractAddressList } = useContractAddress();
  const { freeeTemplates, isFreeeTemplatesLoading, getTemplates, deleteTemplate } = useFreeeTemplates();
  const setIsOpen = useSetRecoilState(isOpenState("freeeTemplateEditModal"));
  const setIsOpenSignUpFreeeModal = useSetRecoilState(isOpenState("signUpFreeeModal"));

  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      try {
        await getTemplates();
      } catch (e) {
        if (e.response && e.response.status === 401) {
          console.log(401);
          setIsOpenSignUpFreeeModal(true);
        } else {
          console.log(500);
          // todo: 何かアラートを出す
        }
      }
    })();
  }, []);

  const adjustment = (v) => {
    let label;
    if (v) label = "決算整理仕訳";
    else label = "日常仕訳";
    return <span>{label}</span>;
  };

  const settledState = (v) => {
    let label;
    if (v === "settled") label = "完了";
    else if (v === "unsettled") label = "未完了";
    return (
      <span>
        <Text type="secondary">決済:</Text> {label}
      </span>
    );
  };

  const accountTypeName = (id) => {
    if (id === undefined) return;
    const v = freeeTemplates.walletables.find((e) => e.id === id);
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">口座:</Text> {v.name}
      </span>
    );
  };

  const partnerName = (id) => {
    if (id === undefined) return;
    const v = freeeTemplates.partners.find((e) => e.id === id);
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">取引先:</Text> {v.name}
      </span>
    );
  };

  const accountItemName = (id) => {
    if (id === undefined) return;
    const v = freeeTemplates.accountItems.find((accountItem) => accountItem.id === id);
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">勘定項目:</Text> {v.name}
      </span>
    );
  };

  const taxName = (taxCode) => {
    if (taxCode === undefined) return;
    const v = freeeTemplates.taxes.find((accountItem) => accountItem.code === taxCode);
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">税区分:</Text> {v.name}
      </span>
    );
  };

  const itemName = (id) => {
    if (id === undefined) return;
    const v = freeeTemplates.items.find((e) => e.id === id);
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">品目:</Text> {v.name}
      </span>
    );
  };

  const sectionName = (id) => {
    if (id === undefined) return;
    const v = freeeTemplates.sections.find((e) => e.id === id);
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">部門:</Text> {v.name}
      </span>
    );
  };

  const memoTagsName = (ids) => {
    const v = ids
      .map((id) => {
        const v = freeeTemplates.memoTags.find((e) => e.id === id);
        if (v === undefined) return;
        return v.name;
      })
      .filter((v) => v !== undefined)
      .join(",");
    if (v === "") return;
    return (
      <span>
        <Text type="secondary">メモタグ:</Text> {v}
      </span>
    );
  };

  const remarks = (v) => {
    if (v === undefined) return;
    return (
      <span>
        <Text type="secondary">備考:</Text> {v}
      </span>
    );
  };

  if (isLoadingContractAddressList || isLoadingWalletAddressList) return; // FIXME

  const walletAddressTagRender = (walletAddress) => {
    const wa = walletAddressList.find((wa) => wa.walletAddress === walletAddress);
    return tagRender(wa);
  };

  const contractAddressTagRender = (contractAddress) => {
    if (contractAddress === "gasJpy") return <Tag color="gray">Gas代</Tag>;
    const ca = contractAddressList.find((ca) => ca.contractAddress === contractAddress);
    return tagRender(ca);
  };

  return (
    <Spin spinning={isFreeeTemplatesLoading}>
      <Form form={form}>
        <EditTemplate />
        <DemandSignInFreeeModal />
        <span>ログイン中の事業所:</span>{" "}
        <span style={{ fontWeight: "bold", fontSize: 16 }}>{freeeTemplates.company.displayName}</span>
        {"　　"}
        <Button
          onClick={async () => {
            await deleteToken();
            window.open(process.env.REACT_APP_FREEE_AUTH_URL, "_blank");
          }}
          type="link"
          size="small"
        >
          事業所を変更
        </Button>
        <div style={{ float: "right" }}>
          <Button
            type="primary"
            onClick={async () => {
              form.resetFields();
              form.setFieldValue(
                "templateId",
                (Math.max(...freeeTemplates.templates.map((t) => t.templateId)) + 1).toString()
              );
              setIsOpen(true);
            }}
            style={{ float: "right" }}
          >
            テンプレート作成
          </Button>
        </div>
        {freeeTemplates.templates.length === 0 && (
          <div style={{ padding: "100px", textAlign: "center" }}>テンプレートは未登録です</div>
        )}
        {freeeTemplates.templates.map((template) => {
          console.log(777, template);
          const cardTitle = (
            <>
              <div style={{ float: "right" }}>
                <Button
                  onClick={async () => {
                    form.setFieldsValue(template);
                    setIsOpen(true);
                  }}
                  type="link"
                  size="small"
                >
                  編集
                </Button>
                <Button
                  onClick={() => deleteTemplate(template.templateId, freeeTemplates.company.companyId)}
                  type="text"
                  size="small"
                  danger
                >
                  削除
                </Button>
              </div>
              <div>
                <span>{template.templateName}</span>
                <span style={{ padding: "10px" }}>{adjustment(template.adjustment)}</span>
                <span style={{ padding: "10px" }}>{template.txnNumber}</span>
                {/* <span style={{ padding: "10px" }}>{accountTypeName(template.accountType)}</span>
                <span style={{ padding: "10px" }}>{partnerName(template.partner)}</span> */}
              </div>
            </>
          );
          return (
            <div key={template.templateId}>
              <Card title={cardTitle} bordered={false} size="small" style={{ marginTop: "16px" }}>
                <List
                  key={template.templateId}
                  dataSource={template.details}
                  renderItem={(item) => (
                    <List.Item>
                      <Row style={{ width: "100%" }}>
                        <Col span={7}>
                          <Title level={5} style={{ textAlign: "center" }}>
                            借方
                          </Title>
                          <div>{accountItemName(item.debit.accountItemId)}</div>
                          <div>{taxName(item.debit.taxCode)}</div>
                          <div>{sectionName(item.debit.sectionId)}</div>
                          <div>{itemName(item.debit.itemId)}</div>
                          <div>{memoTagsName(item.debit.memoTagIds)}</div>
                          {/* <div>{remarks(item.remarks)}</div> */}
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={7}>
                          <Title level={5} style={{ textAlign: "center" }}>
                            貸方
                          </Title>
                          <div>{accountItemName(item.credit.accountItemId)}</div>
                          <div>{taxName(item.credit.taxCode)}</div>
                          <div>{sectionName(item.credit.sectionId)}</div>
                          <div>{itemName(item.credit.itemId)}</div>
                          <div>{memoTagsName(item.credit.memoTagIds)}</div>
                          {/* <div>{remarks(item.remarks)}</div> */}
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={8}>
                          <Title level={5} style={{ textAlign: "center" }}>
                            集計対象
                          </Title>
                          {Object.entries(item.aggregationAddress).map(([walletAddress, cas]) => {
                            return (
                              <div key={walletAddress}>
                                {walletAddressTagRender(walletAddress)}ウォレット：
                                {cas.map((contractAddress, index) => {
                                  if (contractAddress === "gasJpy") {
                                    return (
                                      <span key={contractAddress}>
                                        {contractAddressTagRender(contractAddress)}
                                        {index < cas.length - 1 && "、"}
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span key={contractAddress}>
                                        {contractAddressTagRender(contractAddress)}のNFT売上
                                        {index < cas.length - 1 && "、"}
                                      </span>
                                    );
                                  }
                                })}
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Card>
            </div>
          );
        })}
      </Form>
    </Spin>
  );
};
export default TemplateList;
