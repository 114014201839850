import { useRecoilValueLoadable } from "recoil";
import { useParams } from "react-router-dom";
import { summaryState } from "../../../state/atoms/summaryAtom";

// 参考: https://zenn.dev/apple_yagi/articles/bc52d83ec61aee#api%E9%80%9A%E4%BF%A1%E3%82%92hook%E3%81%AB%E5%88%86%E9%9B%A2%E3%81%99%E3%82%8B%EF%BC%88no-bad%EF%BC%89
export const useFetchSalesSummary = () => {
  const { projectId } = useParams();
  const summaryLoadable = useRecoilValueLoadable(summaryState(projectId));

  let isLoadingSummary;
  switch (summaryLoadable.state) {
    case "hasValue":
      isLoadingSummary = false;
      break;
    case "loading":
      isLoadingSummary = true;
      break;
    case "hasError":
      throw summaryLoadable.contents;
  }

  return { summary: summaryLoadable.contents, isLoadingSummary };
};
