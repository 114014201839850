import React, { useEffect, useState, useRef, useContext } from "react";
import { Alert, Tag, Table, Tooltip, Form, Input, Spin } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { fromUnixTime } from "date-fns";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import styled from "styled-components/macro";
import { filterTransactionQuery } from "../../../state/selectors/filterTransactionQuery";
import { useWalletAddress } from "../../walletAddress/api";
import { useContractAddress } from "../../contractAddress/api";
import { addUnitYen, addUnitCrypto } from "../../../common/unit";
import CustomEmpty from "../../../components/CustomEmpty";
import ChainList from "../../../constant/chainList";
import "../css/transactionTable.css";
import { getBaseUrlOfScan } from "../../../common/util";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            message: "メモは最大20文字です",
            validator: (_, value) => {
              if (value.length > 20) {
                return Promise.reject(new Error("User's memo must be no more than 20 characters"));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TransactionTable = () => {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { walletAddressList, isLoadingWalletAddressList } = useWalletAddress();
  const { contractAddressList, isLoadingContractAddressList } = useContractAddress();
  const transactionList = useRecoilValueLoadable(filterTransactionQuery(projectId));

  const defaultExpandable = {
    rowExpandable: (row) => row.transferDetail.length > 0,
    expandedRowRender: (row) => {
      const columns = [
        {
          title: "from",
          dataIndex: "from",
          width: "300px",
          render: (text) => {
            const wa = walletAddressList.find((wa) => wa.walletAddress === text);
            const baseUrl = `${getBaseUrlOfScan(row.chainId)}address/`;
            if (wa) {
              return (
                <Tooltip title={text}>
                  from:{" "}
                  <Tag color={wa.themeColor} key={wa.name}>
                    {wa.name}
                  </Tag>
                </Tooltip>
              );
            }
            return text === undefined ? (
              "-"
            ) : (
              <Tooltip title={text}>
                from:{" "}
                <a href={baseUrl + text} target="_blank" rel="noopener noreferrer">
                  {text.slice(0, 12)} ... {text.slice(-5)}
                </a>
              </Tooltip>
            );
          },
        },
        {
          title: "to",
          dataIndex: "to",
          width: "300px",
          render: (text) => {
            const wa = walletAddressList.find((wa) => wa.walletAddress === text);
            const baseUrl = `${getBaseUrlOfScan(row.chainId)}address/`;
            if (wa) {
              return (
                <Tooltip title={text}>
                  to:{" "}
                  <Tag color={wa.themeColor} key={wa.name}>
                    {wa.name}
                  </Tag>
                </Tooltip>
              );
            }
            return text === undefined ? (
              "-"
            ) : (
              <Tooltip title={text}>
                to:{" "}
                <a href={baseUrl + text} target="_blank" rel="noopener noreferrer">
                  {text.slice(0, 12)} ... {text.slice(-5)}
                </a>
              </Tooltip>
            );
          },
        },
        {
          dataIndex: "value",
          render: (value, record) => {
            const contractSymbol = record.contractSymbol === undefined ? "" : record.contractSymbol;
            switch (record.detailType) {
              case 0:
                return `${value} ${contractSymbol}`;
              case 1:
                return `${addUnitCrypto(value, row.chainId)} (${addUnitYen(value * record.rateJpy)})`;
              case 2:
                return `${value} ${contractSymbol} (${addUnitYen(value * record.rateJpy)})`;
              case 3:
                return `${contractSymbol} #${record.tokenId}`;
            }
          },
        },
      ];
      return (
        <Table
          showHeader={false}
          pagination={false}
          size="small"
          columns={columns}
          dataSource={row.transferDetail}
          rowClassName={"table-row-light"}
          loading={isLoadingWalletAddressList || isLoadingContractAddressList}
        />
      );
    },
  };

  const [expandable, setExpandable] = useState(defaultExpandable);

  if (isLoadingWalletAddressList || isLoadingContractAddressList || transactionList.state === "loading")
    return (
      <div style={{ position: "relative", width: "100%", minHeight: "70vh" }}>
        <Spin style={{ position: "absolute", top: "20%", left: "50%" }} />
      </div>
    );

  if (transactionList.state === "hasError") {
    return (
      <div
        style={{
          width: "60%",
          height: "70vh",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert
          message="Error"
          description="データの取得ができませんでした。期間指定を短くするか、時間をおいて再度おためしください。"
          type="error"
          style={{ height: "100px" }}
        />
      </div>
    );
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tableProps = {
    expandable,
  };

  const columns = [
    {
      title: "hash",
      dataIndex: "txHash",
      render: (text) => (
        <Tooltip title={text}>
          <div>{text.slice(0, 5)}...</div>
        </Tooltip>
      ),
      // onCell: (record) => {
      //   return { rowSpan: record.rowSpan };
      // },
    },
    {
      title: <div css="text-align: center;">決済時刻</div>,
      dataIndex: "timestamp",
      width: 160,
      sorter: (a, b) => a.timestamp - b.timestamp,
      showSorterTooltip: { title: "クリックで並び順を変更" },
      render: (text) => <div>{fromUnixTime(Number(text)).toLocaleString("ja-JP")}</div>,
      // onCell: (record) => {
      //   return { rowSpan: record.rowSpan };
      // },
    },
    {
      title: "チェーン",
      dataIndex: "chainId",
      align: "center",
      render: (text) => ChainList[text].name,
    },
    {
      title: (
        <div css="text-align: center;">
          <Tooltip title="決済日の午前9時時点のネイティブ通貨のレートです">通貨レート</Tooltip>
        </div>
      ),
      dataIndex: "rateJpy",
      align: "right",
      render: (text) => {
        return addUnitYen(text);
      },
      // onCell: (record) => {
      //   return { rowSpan: record.rowSpan };
      // },
    },
    {
      title: <Tooltip title="トランザクションを発効したアドレスです">取引発行者</Tooltip>,
      dataIndex: "from",
      align: "center",
      render: (text) => {
        const wa = walletAddressList.find((wa) => wa.walletAddress === text);
        return typeof wa === "undefined" ? (
          <div>その他</div>
        ) : (
          <Tooltip title={text}>
            <Tag color={wa.themeColor} key={wa.name}>
              {wa.name}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: <div css="text-align: center;">Gas</div>,
      dataIndex: "gas",
      align: "right",
      render: (text, record) => {
        if (text === undefined) return <div css="text-align: center;">-</div>;
        return (
          <div css="min-width: 60px; text-align: right;">
            {addUnitCrypto(text, record.chainId)} ({addUnitYen(text * record.rateJpy)})
          </div>
        );
      },
    },
    {
      title: <div css="text-align: center;">入出金</div>,
      dataIndex: "depositsAndWithdrawals",
      render: (depositsAndWithdrawals) => {
        return {
          props: {
            style: { minWidth: "50px" },
          },
          children: depositsAndWithdrawals.map((dw) => {
            if (dw.depositsJpy - dw.withdrawalsExceptGasJpy === 0) return;
            const wa = walletAddressList.find((wa) => wa.walletAddress === dw.walletAddress);
            return (
              <li key={wa.walletAddress} style={{ display: "flex", justifyContent: "space-between", padding: 3 }}>
                <Tag color={wa.themeColor} key={wa.name}>
                  {wa.name}
                </Tag>{" "}
                <div>{addUnitYen(dw.depositsJpy - dw.withdrawalsExceptGasJpy)}</div>
              </li>
            );
          }),
        };
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>NFT</div>,
      dataIndex: "transferDetail",
      render: (transferDetailList, record) => {
        return {
          props: {
            style: { minWidth: "50px" },
          },
          children: transferDetailList.reduce((results, transferDetail) => {
            if ("contractAddress" in transferDetail && "tokenId" in transferDetail) {
              const caRegistered = contractAddressList.find(
                (ca) => ca.contractAddress === transferDetail.contractAddress
              );

              const href =
                ChainList[record.chainId].nftMarketUrl +
                "/" +
                transferDetail.contractAddress +
                "/" +
                transferDetail.tokenId;

              if (caRegistered) {
                results.push(
                  <li>
                    <Tag color={caRegistered.themeColor} key={caRegistered.name}>
                      {caRegistered.name}
                    </Tag>{" "}
                    <a href={href} target="_blank" rel="noopener noreferrer">
                      {transferDetail.contractSymbol} #{transferDetail.tokenId}
                    </a>
                  </li>
                );
              } else {
                results.push(
                  <li>
                    <a href={href} target="_blank" rel="noopener noreferrer">
                      {transferDetail.contractSymbol} #{transferDetail.tokenId.slice(0, 12)}{" "}
                      {transferDetail.tokenId.length > 12 && "..."}
                    </a>
                  </li>
                );
              }
            }
            return results;
          }, []),
        };
      },
    },
    // {
    //   title: "メモ",
    //   dataIndex: "userMemo",
    //   key: "userMemo",
    //   //editable: true,
    //   width: 140,
    //   onCell: (record) => {
    //     return { rowSpan: record.rowSpan };
    //   },
    //   render: (text) => (
    //     <>
    //       <span>{text}</span>
    //       <EditOutlined
    //         style={{ color: "#08c", position: "absolute", top: "50%", right: "20px", transform: "translateY(-50%)" }}
    //       />
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "txHash",
      key: "etherscanUrl",
      width: 30,
      render: (text, record) => {
        const baseUrl = `${getBaseUrlOfScan(record.chainId)}tx/`;
        return (
          <a href={baseUrl + text} target="_blank" rel="noopener noreferrer">
            <ExportOutlined />
          </a>
        );
      },
      // onCell: (record) => {
      //   return { rowSpan: record.rowSpan };
      // },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        //handleSave,
      }),
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          locale={{
            emptyText: <CustomEmpty />,
          }}
          {...tableProps}
          columns={mergedColumns}
          dataSource={transactionList.state === "loading" ? [] : transactionList.contents}
          components={components}
          rowClassName={() => "editable-row"}
          size="middle"
          scroll={{ x: "max-content" }}
          loading={transactionList.state === "loading" || isLoadingWalletAddressList}
          sticky={true}
          pagination={false}
        />
      </Form>
      <div css="height: 10px;"></div> {/* テーブルが痙攣しないようにするための応急処置 */}
    </>
  );
};

export default TransactionTable;
