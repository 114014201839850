import { useParams } from "react-router-dom";
import { useRecoilStateLoadable } from "recoil";
import { axiosClient } from "../../../common/axiosClient";
import { notifyError } from "../../../common/notify";
import { walletAddressListState } from "../../../state/atoms/walletAddressListAtom";

export const useWalletAddress = () => {
  const { projectId } = useParams();

  const [walletAddressListLoadable, setWalletAddressList] = useRecoilStateLoadable(walletAddressListState(projectId));

  let isLoadingWalletAddressList;
  switch (walletAddressListLoadable.state) {
    case "hasValue":
      isLoadingWalletAddressList = false;
      break;
    case "loading":
      isLoadingWalletAddressList = true;
      break;
    case "hasError":
      throw walletAddressListLoadable.contents;
  }

  const registerWalletAddress = async (postObject) => {
    const result = await axiosClient.post(
      `${process.env.REACT_APP_API_HOST}/${projectId}/walletAddress/register`,
      postObject
    );

    setWalletAddressList((prev) => [...prev, result.data]);

    return walletAddressListLoadable.contents;
  };

  const updateWalletAddress = async (values) => {
    const postObject = {
      walletAddress: values.walletAddress,
      name: values.name,
      memo: values.memo,
      themeColor: values.themeColor,
      invalid: values.invalid,
    };
    try {
      const result = await axiosClient.post(
        `${process.env.REACT_APP_API_HOST}/${projectId}/walletAddress/update`,
        postObject
      );
      setWalletAddressList(
        walletAddressListLoadable.contents.map((value) =>
          value.walletAddress === result.data.walletAddress ? result.data : value
        )
      );
    } catch (e) {
      console.error(e);
      notifyError("編集に失敗しました");
    }
  };

  const updateBalance = async () => {
    try {
      const result = await axiosClient.post(`${process.env.REACT_APP_API_HOST}/${projectId}/balance`, {});
      setWalletAddressList(result.data);
    } catch (e) {
      console.error(e);
      notifyError("更新に失敗しました");
    }
  };

  return {
    walletAddressList: walletAddressListLoadable.contents,
    isLoadingWalletAddressList,
    registerWalletAddress,
    updateWalletAddress,
    updateBalance,
  };
};
