import { Link } from "react-router-dom";
import { Card, Spin } from "antd";
import { useRecoilValueLoadable } from "recoil";
//import { P5 } from "../../../components/P5";  // FIXME: 開発環境にdeployするとerrorになる...
import { projectListState } from "../../../state/atoms/projectListAtom";
const { Meta } = Card;

const RGBList = [
  { r: 153, g: 204, b: 255 },
  { r: 51, g: 255, b: 153 },
  { r: 255, g: 153, b: 51 },
];

const ProjectCard = () => {
  const projectList = useRecoilValueLoadable(projectListState);

  if (projectList.state === "loading") {
    return <Spin></Spin>;
  } else if (projectList.state === "hasValue") {
    const cardList = projectList.contents.projects.map((project) => {
      return (
        <Link key={`${project.projectId}`} to={`/${project.projectId}/dashboard`}>
          <div style={{ padding: 10 }}>
            <Card
              hoverable
              style={{
                width: 240,
                height: 150,
              }}
              // cover={
              //   <P5
              //     r={RGBList[index % RGBList.length].r}
              //     g={RGBList[index % RGBList.length].g}
              //     b={RGBList[index % RGBList.length].b}
              //   />
              // }
            >
              <Meta title={project.name} />
            </Card>
          </div>
        </Link>
      );
    });
    return <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>{cardList}</div>;
  }
};
export default ProjectCard;
