import axios from "axios";
import { getIdToken } from "./auth";

/**
 * デフォルト config の設定
 */
export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
    // Authorization: await getIdToken(),
  },
});

/**
 * リクエスト インターセプター
 */
axiosClient.interceptors.request.use(async (config) => {
  if (config.headers !== undefined) {
    // ヘッダにアクセストークンを埋める
    const accessToken = await getIdToken();
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
  }
  return config;
});

/**
 * レスポンス インターセプター
 */
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
