import { Button, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSetRecoilState, useRecoilValue } from "recoil";
import ProjectCard from "../components/ProjectCard";
import RegisterProject from "../components/RegisterProject";
import { isOpenState } from "../../../state/atoms/isOpen";
import { projectListState } from "../../../state/atoms/projectListAtom";

const ProjectList = () => {
  const setIsOpen = useSetRecoilState(isOpenState("projectRegistrationModal"));
  const projectList = useRecoilValue(projectListState);

  return (
    <>
      <div style={{ padding: 10 }}>
        <Button
          type="primary"
          onClick={() => setIsOpen(true)}
          disabled={projectList.projects.length === projectList.maximumNumberOfProject}
        >
          新規作成
        </Button>
        {"　"}
        <Tooltip
          title={`プロジェクトは最大${projectList.maximumNumberOfProject}個まで作成できます。${
            projectList.maximumNumberOfProject + 1
          }個以上必要な場合はお問合せください。`}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <ProjectCard />
      <RegisterProject />
    </>
  );
};

export default ProjectList;
