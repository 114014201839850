import { selectorFamily } from "recoil";
import { getUnixTime, parseISO } from "date-fns";
import { axiosClient } from "../../common/axiosClient";
import { notifyError } from "../../common/notify";
import { searchPeriodState } from "../atoms/searchPeriodAtom";
import { walletAddressListState } from "../atoms/walletAddressListAtom";

export const fetchTransactions = async (projectId, timestampSince, timestampUntil) => {
  try {
    const response = await axiosClient.get(
      `${process.env.REACT_APP_API_HOST}/${projectId}/transaction?since=${timestampSince}&until=${timestampUntil}`
    );
    return formatTxs(response.data);
  } catch (e) {
    notifyError("通信に失敗しました。期間指定を短くするか、時間をおいて再度おためしください。");
    throw e;
  }
};

const formatTxs = (txs, walletAddressList) => {
  return txs.map((tx) => {
    const profitAndLossByTx = tx.transferDetail.reduce((results, current) => {
      if (!current.isAggregated) return results;

      // TODO: backend と共通化する
      if (walletAddressList.find((wa) => wa.walletAddress === current.from)) {
        const walletAddressFrom = results.find((value) => value.walletAddress === current.from);
        if (walletAddressFrom) {
          walletAddressFrom.withdrawals += current.value;
        } else {
          results.push({
            walletAddress: current.from,
            deposits: 0,
            depositsJpy: 0,
            withdrawals: current.value,
            withdrawalsJpy: current.value * current.rateJpy,
          });
        }
      }

      if (walletAddressList.find((wa) => wa.walletAddress === current.to)) {
        const walletAddressTo = results.find((value) => value.walletAddress === current.to);
        if (walletAddressTo) {
          walletAddressTo.withdrawals += current.value;
        } else {
          results.push({
            walletAddress: current.to,
            deposits: current.value,
            depositsJpy: current.value * current.rateJpy,
            withdrawals: 0,
            withdrawalsJpy: 0,
          });
        }
      }

      return results;
    }, []);

    tx.profitAndLoss = profitAndLossByTx;
    return tx;
  });
};

export const transactionQuery = selectorFamily({
  key: "transaction",
  get:
    (projectId) =>
    async ({ get }) => {
      const searchPeriod = get(searchPeriodState(projectId));
      const walletAddressList = get(walletAddressListState(projectId));

      const timestampSince = getUnixTime(parseISO(searchPeriod.since, "yyyy-MM-dd", new Date()));
      const timestampUntil = getUnixTime(parseISO(searchPeriod.until, "yyyy-MM-dd", new Date())) + 86400;

      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_HOST}/${projectId}/transaction?since=${timestampSince}&until=${timestampUntil}`
      );
      return formatTxs(response.data, walletAddressList);
    },
});
