import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedWalletAddressState } from "../../../state/atoms/selectedWalletAddressAtom";

export const useSelectedWalletAddress = () => {
  const { projectId } = useParams();

  const [selectedWalletAddress, setSelectedWalletAddress] = useRecoilState(selectedWalletAddressState(projectId));

  return { selectedWalletAddress, setSelectedWalletAddress };
};
