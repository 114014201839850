import { Form, Space, Spin, Table, Tag, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSetRecoilState } from "recoil";
import CustomEmpty from "../../../components/CustomEmpty";
import { editAddressState } from "../../../state/atoms/editAddressAtom";
import { isOpenState } from "../../../state/atoms/isOpen";
import styled from "styled-components";
import { getBaseUrlOfScan } from "../../../common/util";
import { useWalletAddress } from "../api";

const { Text } = Typography;

const StyledDisabled = styled.div`
  color: #b5b5b5;
  pointer-events: none;
`;

const SpinBackground = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`;

const WalletAddressList = () => {
  const [form] = Form.useForm();

  const { walletAddressList, isLoadingWalletAddressList, updateWalletAddress } = useWalletAddress();

  const setIsOpen = useSetRecoilState(isOpenState("walletAddressEditModal"));
  const setEditWalletAddressInfo = useSetRecoilState(editAddressState("walletAddress"));

  if (isLoadingWalletAddressList)
    // TODO: loading 画面は共有コンポーネントとして作成した方が良い
    return (
      <SpinBackground>
        <Spin style={{ aline: "center" }} />
      </SpinBackground>
    );

  const etherscanBaseUrl = `${getBaseUrlOfScan(1)}address/`;
  const polygonscanBaseUrl = `${getBaseUrlOfScan(137)}address/`;
  const subscanBaseUrl = `${getBaseUrlOfScan(592)}account/`;

  const columns = [
    {
      title: "ウォレットアドレス",
      dataIndex: "walletAddress",
      key: "walletAddress",
      render: (text, record) => {
        return (
          <>
            <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
              {record.invalid ? <StyledDisabled>{text}</StyledDisabled> : text}
            </div>
            <div>
              <a href={etherscanBaseUrl + text} target="_blank" rel="noopener noreferrer">
                Etherscan
              </a>{" "}
              <a href={polygonscanBaseUrl + text} target="_blank" rel="noopener noreferrer">
                Polygonscan
              </a>{" "}
              <a href={subscanBaseUrl + text} target="_blank" rel="noopener noreferrer">
                Subscan
              </a>
            </div>
          </>
        );
      },
    },
    {
      title: "表示名",
      dataIndex: "name",
      key: "name",
      editable: true,
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          <Tag color={record.themeColor} key={text}>
            {text}
          </Tag>
        </div>
      ),
    },
    {
      title: "メモ",
      dataIndex: "memo",
      key: "memo",
      editable: true,
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {record.invalid ? <StyledDisabled>{text}</StyledDisabled> : text}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: 70,
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              setEditWalletAddressInfo({
                walletAddress: record.walletAddress,
                name: record.name,
                memo: record.memo,
                themeColor: record.themeColor,
              });
              setIsOpen(true);
            }}
          >
            <EditOutlined />
          </Typography.Link>
        );
      },
    },
    {
      title: "同期日時",
      dataIndex: "lastSyncedTime",
      key: "lastSyncedTime",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {record.invalid ? (
            <StyledDisabled>{text === 0 ? "未同期" : new Date(text).toLocaleString("ja-JP")}</StyledDisabled>
          ) : text === 0 ? (
            "未同期"
          ) : (
            new Date(text).toLocaleString("ja-JP")
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "settings",
      key: "settings",
      render: (_, record) => (
        <Space size="middle">
          <a
            href="/#"
            key="list-invalid"
            onClick={async (e) => {
              e.preventDefault();
              const postObject = {
                walletAddress: record.walletAddress,
                name: record.name,
                memo: record.memo,
                themeColor: record.themeColor,
                invalid: !record.invalid,
              };
              await updateWalletAddress(postObject);
            }}
          >
            {record.invalid ? "有効にする" : <Text type="danger">無効にする</Text>}
          </a>
          {/* <a key="list-more">削除</a> */}
        </Space>
      ),
    },
  ];

  const walletAddressListAddedKey = walletAddressList.map((wa) => {
    return {
      ...wa,
      key: wa.walletAddress,
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          locale={{
            emptyText: <CustomEmpty />,
          }}
          columns={columns}
          dataSource={walletAddressListAddedKey}
          loading={isLoadingWalletAddressList}
        />
      </Form>
    </>
  );
};

export default WalletAddressList;
