import { axiosClient } from "../../../common/axiosClient";
import { notifyError } from "../../../common/notify";

export const createUser = async (email) => {
  const postObject = {
    email: email,
  };
  try {
    const result = await axiosClient.post(`/user/register`, postObject);
    return result.data;
  } catch (e) {
    console.error(e);
    notifyError(e);
  }
};

export const fetchUsers = async () => {
  try {
    const result = await axiosClient.get(`/user`);
    return result.data;
  } catch (e) {
    console.error(e);
    notifyError(e);
  }
};

export const deleteUser = async (email) => {
  const postObject = {
    email: email,
  };
  try {
    const result = await axiosClient.post(`/user/delete`, postObject);
    return result;
  } catch (e) {
    console.error(e);
    notifyError(e);
  }
};
