import { Button, Spin } from "antd";
import { useAuthFreee } from "../api";

const FreeeSyncCompletion = () => {
  const { isSuccess, useAuth } = useAuthFreee();

  useAuth();

  let text = "";
  if (isSuccess === "success") text = "freee 連携が完了しました。このタブは閉じてください。";
  else if (isSuccess === "error") text = "エラーが発生し freee 連携ができませんでした。";
  else text = "freee 連携中...";

  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin spinning={isSuccess === "loading"}>
        <div>
          <div>{text}</div>
          <div style={{ width: "116px", margin: "0 auto", paddingTop: "10px" }}>
            <Button onClick={() => window.close()}>タブを閉じる</Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default FreeeSyncCompletion;
