import { atom, selector } from "recoil";
import { fetchUsers } from "../../features/user/api";

//参考：https://github.com/TomoyukiMatsuda/React-pra/tree/next-sample/axios-recoil/next-sample/src/grobalStates
export const userListState = atom({
  key: "UserList",
  default: selector({
    key: "savedUserList",
    get: async () => {
      const response = await fetchUsers();
      return response.data;
    },
  }),
});
