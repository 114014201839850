import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";

const { Content } = Layout;

const Background = () => {
  return (
    <div>
      <Header />
      <Content className="site-layout" style={{ padding: "0 30px", marginTop: 16 }}>
        <Outlet />
      </Content>
    </div>
  );
};

export default Background;
