import { Tag, Button, Spin, Tooltip } from "antd";
import BalanceDoughnutChart from "./BalanceDoughnutChart";
import { useWalletAddress } from "../api";
import { useState } from "react";
import { format, fromUnixTime, getUnixTime } from "date-fns";

const legendList = [
  {
    symbol: "ETH",
    rgb: [47, 47, 48],
  },
  {
    symbol: "Matic",
    rgb: [123, 67, 218],
  },
  {
    symbol: "ASTR",
    rgb: [3, 175, 240],
  },
  {
    symbol: "WETH",
    rgb: [226, 28, 115],
  },
  {
    symbol: "USDC",
    rgb: [37, 113, 196],
  },
  {
    symbol: "WBTC",
    rgb: [229, 138, 63],
  },
];

const minimumUpdateInterval = 60; //6 * 60 * 60;

const Balance = () => {
  const { walletAddressList, isLoadingWalletAddressList, updateBalance } = useWalletAddress();
  const [loading, setLoading] = useState(false);

  if (isLoadingWalletAddressList) {
    return <></>;
  }

  // walletAddress 未登録状態では何も表示しない
  if (walletAddressList.length === 0) {
    return <></>;
  }

  const lastSyncBalanceTime = [...walletAddressList]
    .sort((a, b) => a.syncBalanceAt - b.syncBalanceAt)
    .at(0).syncBalanceAt;
  const ableToUpdate =
    lastSyncBalanceTime === undefined || getUnixTime(new Date()) - lastSyncBalanceTime > minimumUpdateInterval;

  const handleBalanceUpdate = async () => {
    setLoading(true);
    await updateBalance();
    setLoading(false);
  };

  return (
    <Spin spinning={loading} size="large">
      <div style={{ backgroundColor: "white", paddingTop: 10, marginBottom: 10 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {legendList.map((legend) => (
              <span style={{ paddingLeft: 10 }} key={legend.symbol}>
                <Tag color={`rgba(${legend.rgb.join(",")}, 0.3)`} fill="outline">
                  {"　　"}
                </Tag>
                {legend.symbol}
              </span>
            ))}
          </div>

          <Tooltip
            title={
              !ableToUpdate &&
              `${format(
                fromUnixTime(lastSyncBalanceTime + minimumUpdateInterval),
                "yyyy/MM/dd HH:mm:ss"
              )}以降に更新可能です`
            }
          >
            <Button onClick={handleBalanceUpdate} style={{ marginRight: 10 }} disabled={!ableToUpdate}>
              更新
            </Button>
          </Tooltip>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
          {walletAddressList.map((wa) => (
            <BalanceDoughnutChart walletAddress={wa} key={wa.walletAddress} />
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default Balance;
