import { useState, useEffect } from "react";
import { Form, Input, Spin, Modal, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { CirclePicker } from "react-color";
import { useContractAddress, useContractMetaData } from "../api";
import NftMetaData from "./NftMetaData";
import { editAddressState } from "../../../state/atoms/editAddressAtom";
import { isOpenState } from "../../../state/atoms/isOpen";

const { Option } = Select;

const layout = {
  labelCol: {
    offset: 1,
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const EditContractAddress = () => {
  const [form] = Form.useForm();

  const { updateContractAddress } = useContractAddress();
  const { fetchContractMetaData } = useContractMetaData();

  const editContractAddressInfo = useRecoilValue(editAddressState("contractAddress"));
  const [themeColorHex, setThemeColorHex] = useState("#f44336");
  const [modalIsOpen, setIsOpen] = useRecoilState(isOpenState("contractAddressEditModal"));
  const [loading, setLoading] = useState(false);

  const { contractMetaData, loadingContractMetaData } = fetchContractMetaData(
    editContractAddressInfo.chainId,
    editContractAddressInfo.contractAddress
  );

  useEffect(() => {
    form.setFieldsValue(editContractAddressInfo);
    setThemeColorHex(editContractAddressInfo.themeColor);
  }, [form, editContractAddressInfo.contractAddress]);

  const onEditContractAddress = async () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        await updateContractAddress(values);
        setLoading(false);
        setIsOpen(false);
        form.resetFields();
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        title="コントラクトアドレスの情報を編集"
        width={1000}
        visible={modalIsOpen}
        okText="更新"
        cancelText="戻る"
        onOk={onEditContractAddress}
        onCancel={() => setIsOpen(false)}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form {...layout} form={form} name="control-hooks">
            <Form.Item name="chainId" label="チェーン" initialValue={editContractAddressInfo.chainId}>
              <Select
                disabled
                defaultValue={{
                  value: editContractAddressInfo.chainId,
                  label: editContractAddressInfo.chainId === 137 ? "Polygon" : "Ethereum",
                }}
                style={{ width: 120 }}
              >
                <Option value={1}>Ethereum</Option>
                <Option value={137}>Polygon</Option>
                <Option value={592}>Astar</Option>
              </Select>
            </Form.Item>
            <Form.Item name="contractAddress" label="アドレス" initialValue={editContractAddressInfo.contractAddress}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="metaData" label="メタデータ">
              {loadingContractMetaData ? <Spin /> : <NftMetaData {...contractMetaData} />}
            </Form.Item>
            <Form.Item
              name="name"
              label="表示名"
              initialValue={editContractAddressInfo.name}
              rules={[
                {
                  required: true,
                  message: "好きな名前を入力してください",
                },
              ]}
            >
              <Input placeholder="○○用" />
            </Form.Item>

            <Form.Item
              name="methodNamesAggregated"
              label={
                <div>
                  メソッド{" "}
                  <Tooltip title="ここで指定したメソッドの呼び出し回数を月次で集計して表示します。大文字と小文字の区別はありません。">
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              initialValue={editContractAddressInfo.methodNamesAggregated}
            >
              <Input placeholder="transferfrom,approve" />
            </Form.Item>

            <Form.Item name="memo" label="メモ" initialValue={editContractAddressInfo.memo}>
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="themeColor"
              label="テーマカラー"
              initialValue={editContractAddressInfo.themeColor}
              rules={[
                {
                  required: true,
                  message: "好きな色を選択してください",
                },
              ]}
              getValueFromEvent={(args) => args.hex}
            >
              <CirclePicker width="690" color={themeColorHex} onChange={(color) => setThemeColorHex(color.hex)} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default EditContractAddress;
