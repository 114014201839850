import { Tooltip, Tag } from "antd";
//import { Chart, ArcElement, Title, LegendOptions, ActiveDataPoint} from "chart.js";
import { format, fromUnixTime } from "date-fns";
import { Doughnut } from "react-chartjs-2";
//Chart.register(ArcElement, Title, Legend);
import "chart.js/auto";
import ChainList from "../../../constant/chainList";

const drawInnerText = (chart) => {
  let ctx = chart.ctx;
  ctx.restore();
  const fontSize = (chart.height / 114).toFixed(2);
  ctx.font = fontSize + "em sans-serif";
  ctx.textBaseline = "middle";
  const text = chart.config._config.data.datasets[0].data
    .reduce((a, b) => a + b, 0)
    .toLocaleString("ja-JP", { style: "currency", currency: "JPY" });
  let textX = Math.round((chart.width - ctx.measureText(text).width) / 2);
  let textY = chart.titleBlock.height / 2 + chart.height / 2 + chart.legend.height / 2;
  ctx.fillText(text, textX, textY);
  ctx.fillStyle = "#000";
  ctx.save();
};

const BalanceDoughnutChart = (props) => {
  const nativeCurrencyData = {
    labels: [],
    datasets: [
      {
        data: [],
        label: "¥",
        backgroundColor: [],
        borderColor: [],
        borderWidth: 0,
      },
    ],
  };

  props.walletAddress.nativeCurrencyBalance?.map((ncb) => {
    nativeCurrencyData.labels.push(`${Math.round(ncb.balance * 10000) / 10000}${ChainList[ncb.chainId].currency}`);
    nativeCurrencyData.datasets[0].data.push(ncb.balance * ncb.rateJpy);
    nativeCurrencyData.datasets[0].backgroundColor.push(`rgba(${ncb.rgb.join(",")}, 0.2)`);
    nativeCurrencyData.datasets[0].borderColor.push(`rgba(${ncb.rgb.join(",")}, 1)`);
  });

  const tokenData = {
    labels: [],
    datasets: [
      {
        label: "¥",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 0,
      },
    ],
  };

  props.walletAddress.tokenBalance?.map((tb) => {
    tokenData.labels.push(
      `${Math.round(tb.balance * 10000) / 10000}${tb.symbol}(${tb.chainId === 1 ? "ETH" : "Polygon"})`
    );
    tokenData.datasets[0].data.push(tb.balance * tb.rateJpy);
    tokenData.datasets[0].backgroundColor.push(`rgba(${tb.rgb.join(",")}, 0.2)`);
    tokenData.datasets[0].borderColor.push(`rgba(${tb.rgb.join(",")}, 1)`);
  });

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Doughnut
          data={nativeCurrencyData}
          options={{
            maintainAspectRatio: false,
            responsive: false,
            plugins: {
              title: {
                display: true,
                text: "ネイティブ通貨",
              },
              legend: {
                display: false,
              },
            },
          }}
          width={180}
          height={180}
          plugins={[
            {
              beforeDraw: function (chart) {
                drawInnerText(chart);
              },
            },
          ]}
        />
        <Doughnut
          data={tokenData}
          options={{
            maintainAspectRatio: false,
            responsive: false,
            plugins: {
              title: {
                display: true,
                text: "ERC20 Token",
              },
              legend: {
                display: false,
              },
            },
          }}
          width={180}
          height={180}
          plugins={[
            {
              beforeDraw: function (chart) {
                drawInnerText(chart);
              },
            },
          ]}
        />
      </div>
      <div style={{ textAlign: "center", paddingTop: 5, color: "gray" }}>
        <Tooltip title={props.walletAddress.walletAddress}>
          <Tag color={props.walletAddress.themeColor} key={props.walletAddress.name}>
            {props.walletAddress.name}
          </Tag>
        </Tooltip>
        {props.walletAddress.syncBalanceAt === 0 || !("syncBalanceAt" in props.walletAddress) ? (
          "データ未取得"
        ) : (
          <Tooltip title={format(fromUnixTime(props.walletAddress.syncBalanceAt), "yyyy/MM/dd HH:mm:ss")}>
            （{format(fromUnixTime(props.walletAddress.syncBalanceAt), "yyyy/MM/dd")}時点）
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default BalanceDoughnutChart;
