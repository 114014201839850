import { atomFamily, selectorFamily } from "recoil";
import { axiosClient } from "../../common/axiosClient";

//参考：https://recoiljs.org/docs/guides/asynchronous-data-queries#queries-with-parameters
export const contractAddressListState = atomFamily({
  key: "ContractAddressList",
  default: selectorFamily({
    key: "ContractAddressList/Default",
    get: (projectId) => async () => {
      const response = await axiosClient.get(`/${projectId}/contractAddress/list`);
      response.data.result.map((ca) => {
        if (ca.methodNamesAggregated !== undefined) {
          ca.methodNamesAggregated = ca.methodNamesAggregated.join(",");
        }
      });
      return response.data.result;
    },
  }),
});
