import { useEffect, useState } from "react";
import { Popconfirm, Table, message } from "antd";
import { useRecoilState } from "recoil";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUsers, deleteUser } from "../api";
import { userListState } from "../../../state/atoms/userListAtom";

const UserList = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [userList, setUserList] = useRecoilState(userListState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const result = await fetchUsers();
      setUserList(result);
    };
    setLoading(true);
    fetchUserData();
    setLoading(false);
  }, []);

  const columns = [
    {
      title: "名前",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <span>
          {record.familyName} {record.givenName}
        </span>
      ),
    },
    {
      title: "メールアドレス",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "ステータス",
      dataIndex: "userStatus",
      key: "userStatus",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        if (record.email === user.attributes.email) {
          return;
        }
        return (
          <Popconfirm
            title="本当に削除しますか？"
            onConfirm={async (e) => {
              e.preventDefault();
              try {
                await deleteUser(record.email);
                setUserList(userList.filter((user) => user.email !== record.email));
                message.success(`${record.email}を削除しました`);
              } catch (e) {
                message.error("ユーザーを削除できませんでした");
              }
            }}
            okText="はい"
            cancelText="いいえ"
          >
            <a
              href="/#"
              key="list-delete"
              onClick={async (e) => {
                e.preventDefault();
              }}
            >
              Delete
            </a>
          </Popconfirm>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={userList} loading={loading} />;
};

export default UserList;
