import { Spin, Typography, Tooltip as AntdTooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { useFetchSalesSummary } from "../api";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const { Title: AntdTitle } = Typography;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

// オプションやデータを動的に変更する場合
// https://www.chartjs.org/docs/latest/developers/api.html

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

const TransactionCountBarChart = () => {
  const { summary, isLoadingSummary } = useFetchSalesSummary();

  if (isLoadingSummary) {
    return (
      <div style={{ backgroundColor: "white", padding: 10 }}>
        <Spin />
      </div>
    );
  }

  const today = new Date();
  const threeMonthsAgo = new Date().setMonth(today.getMonth() - 12);

  const labels = summary.filter((s) => threeMonthsAgo < new Date(s.key) && new Date(s.key) < today).map((s) => s.key);

  const barThickness = 10; // TODO: データ数に応じて調整するように

  const datasets = labels.reduce(
    (accumulator, label) => {
      const targetSalesSummary = summary.find((s) => s.key === label);
      accumulator.at(0).data.push(targetSalesSummary.transactionCount);
      return accumulator;
    },
    [
      {
        data: [],
        backgroundColor: `hsl(200, 80%, 60%)`,
        barThickness: barThickness,
        maxBarThickness: barThickness,
      },
    ]
  );

  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: "16px",
        boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <FlexBetween>
        <AntdTitle level={5}>
          トランザクション数{" "}
          <AntdTooltip title="登録されたウォレットに関連する同期済みのトランザクション数">
            <InfoCircleOutlined />
          </AntdTooltip>
        </AntdTitle>
      </FlexBetween>
      <Bar options={options} data={data} />
    </div>
  );
};

export default TransactionCountBarChart;
