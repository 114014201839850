import { atomFamily, selectorFamily } from "recoil";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { syncEffect } from "recoil-sync";
import { dict, string } from "@recoiljs/refine";

export const searchPeriodState = atomFamily({
  key: "SearchPeriod",
  default: selectorFamily({
    key: "SearchPeriod/Default",
    get: () => () => {
      const now = new Date();
      return {
        since: format(startOfMonth(now), "yyyy-MM-dd"),
        until: format(endOfMonth(now), "yyyy-MM-dd"),
      };
    },
  }),
  effects: [syncEffect({ refine: dict(string()) })],
});
