import { atomFamily, selectorFamily } from "recoil";
import { walletAddressListState } from "./walletAddressListAtom";

export const selectedWalletAddressState = atomFamily({
  key: "SelectedWalletAddress",
  default: selectorFamily({
    key: "SelectedWalletAddress/Default",
    get:
      (projectId) =>
      async ({ get }) => {
        const defaultSelectedWalletAddress = get(walletAddressListState(projectId)).map((wa) => {
          return {
            label: wa.name,
            value: wa.walletAddress,
          };
        });
        defaultSelectedWalletAddress.push({ label: "その他", value: "other" });
        return defaultSelectedWalletAddress;
      },
  }),
});
