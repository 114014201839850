import { Tag, Row, Col, Select, Space, Button } from "antd";
import { subMonths, startOfMonth, endOfMonth, parseISO, fromUnixTime } from "date-fns";
import DatePicker from "./DatePicker";
import { CSVLink } from "react-csv";
import CustomEmpty from "../../../components/CustomEmpty";
import { useParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { filterTransactionQuery } from "../../../state/selectors/filterTransactionQuery";
import { useWalletAddress } from "../../walletAddress/api";
import { useSearchPeriod } from "../hooks/useSearchPeriod";
import { useSelectedWalletAddress } from "../hooks/useSelectedWalletAddress";
import styled from "styled-components";

const { RangePicker } = DatePicker;

const StyledBackground = styled.div`
  background-color: #f5f5f5;
`;

const StyledForm = styled.div`
  padding: 10px;
`;

const csvFileHeaders = [
  { label: "hash", key: "hash" },
  { label: "datetime(JST)", key: "datetime" },
  { label: "ethJpy", key: "ethJpy" },
  { label: "cryptoJpy", key: "cryptoJpy" },
  { label: "from", key: "from" },
  { label: "to", key: "to" },
  { label: "depositAndWithdrawal", key: "depositAndWithdrawal" },
  { label: "depositAndWithdrawalJpy", key: "depositAndWithdrawalJpy" },
  { label: "tokenName", key: "tokenName" },
  { label: "gas", key: "gas" },
  { label: "gasJpy", key: "gasJpy" },
  { label: "contractAddress", key: "addressesFromLogs.contractAddress" },
  { label: "contractDisplayName", key: "addressesFromLogs.name" },
  { label: "txType", key: "txType" },
  { label: "chain", key: "chain" },
];

const SearchCondition = () => {
  const now = new Date();

  const { projectId } = useParams();
  const transactionList = useRecoilValueLoadable(filterTransactionQuery(projectId));

  const { walletAddressList, isLoadingWalletAddressList } = useWalletAddress();
  const { searchPeriod, updateSearchPeriod } = useSearchPeriod();
  const { selectedWalletAddress, setSelectedWalletAddress } = useSelectedWalletAddress();

  if (isLoadingWalletAddressList) return <>Loading...</>;

  const optionsWalletAddress = walletAddressList.map((wa) => {
    return {
      label: wa.name,
      value: wa.walletAddress,
    };
  });
  optionsWalletAddress.push({ label: "その他", value: "other" });

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const address = walletAddressList.find((wa) => wa.walletAddress === value);

    return (
      <Tag
        color={address ? address.themeColor : "gray"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      updateSearchPeriod({ since: dateStrings[0], until: dateStrings[1] });
    } else {
      console.log("Clear");
    }
  };

  return (
    <>
      <StyledBackground>
        <StyledForm>
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Row align="middle" justify="end">
              <Col span={4}>期間</Col>
              <Col span={10}>
                <RangePicker
                  ranges={{
                    前々月: [startOfMonth(subMonths(now, 2)), endOfMonth(subMonths(now, 2))],
                    前月: [startOfMonth(subMonths(now, 1)), endOfMonth(subMonths(now, 1))],
                    今月: [startOfMonth(now), endOfMonth(now)],
                  }}
                  value={[
                    parseISO(searchPeriod.since, "yyyy-MM-dd", now),
                    parseISO(searchPeriod.until, "yyyy-MM-dd", now),
                  ]}
                  onCalendarChange={onChange}
                />
              </Col>
              <Col span={10}>
                <div style={{ textAlign: "right" }}>
                  {transactionList.state === "loading" ? (
                    "loading...　"
                  ) : (
                    <span>
                      {transactionList.contents.length}件を表示中{"　"}
                    </span>
                  )}
                </div>
                {/* <CSVLink
                  data={[].map((tx) => {
                    //TODO: transactionList に変える
                    const d = fromUnixTime(Number(tx.timeStamp)).toLocaleString("ja-JP");
                    return { ...tx, d };
                  })}
                  filename={`txList_${searchPeriod.since}_${searchPeriod.until}.csv`}
                  headers={csvFileHeaders}
                  style={{ float: "right", marginLeft: 15 }}
                >
                  <Button disabled={true}>CSV出力</Button>
                </CSVLink> */}
              </Col>
            </Row>
            <Row align="middle" justify="end">
              <Col span={4}>ウォレット</Col>
              <Col span={20}>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  value={selectedWalletAddress}
                  options={optionsWalletAddress}
                  onChange={(_, option) => {
                    setSelectedWalletAddress(option);
                  }}
                  placeholder="Select wallet address..."
                  maxTagCount="responsive"
                  tagRender={tagRender}
                  allowClear={true}
                  notFoundContent={
                    <CustomEmpty description="データがありません。設定からウォレットアドレスを登録してください。" />
                  }
                />
              </Col>
            </Row>
          </Space>
        </StyledForm>
      </StyledBackground>
    </>
  );
};

export default SearchCondition;
