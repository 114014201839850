import { CaretRightOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Form, Input, Select, Tooltip, Tag, Button, Divider, Row, Col } from "antd";
import styled from "styled-components";
import { useWalletAddress } from "../../walletAddress/api";
import { useContractAddress } from "../../contractAddress/api";
import { useFreeeTemplates } from "../api";
import SearchSelect from "../../../components/SearchSelect";

const { Panel } = Collapse;
const { TextArea } = Input;

const MarginDiv = styled.div`
  margin-left: 10px;
  margin-top: 10px;
`;

const tagRender = (address) => {
  return (
    <Tag
      color={address === undefined ? "gray" : address.themeColor}
      style={{
        marginRight: 3,
      }}
    >
      <Tooltip title={address.walletAddress || address.contractAddress}>{address.name}</Tooltip>
    </Tag>
  );
};

const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const TableInTemplate = ({ templateSetting, templateIndex }) => {
  const form = Form.useFormInstance();

  const { walletAddressList, isLoadingWalletAddressList } = useWalletAddress();
  const { contractAddressList, isLoadingContractAddressList } = useContractAddress();
  const { freeeTemplates } = useFreeeTemplates();

  if (isLoadingContractAddressList || isLoadingWalletAddressList) return; // FIXME

  const plainOptions = contractAddressList.map((ca) => {
    return {
      label: (
        <span>
          {tagRender(ca)}のNFT売上
          <Divider type="vertical" style={{ borderLeft: "1px solid grey" }} />
        </span>
      ),
      value: ca.contractAddress,
    };
  });
  plainOptions.push({ label: tagRender({ name: "Gas代" }), value: "gasJpy" });

  const accountItemsOptions = freeeTemplates.accountItems.map((accountItem) => {
    return { value: accountItem.id, label: accountItem.name };
  });

  const onChangeAccountItem = (detailsIndex, entrySide) => {
    const accountItemIdNamePath = templateSetting
      ? ["details", detailsIndex, entrySide, "accountItemId"]
      : [templateIndex, "details", detailsIndex, entrySide, "accountItemId"];
    const selectedAccountItem = freeeTemplates.accountItems.find(
      (accountItem) => accountItem.id === form.getFieldValue(accountItemIdNamePath)
    );

    const taxCodeNamePath = templateSetting
      ? ["details", detailsIndex, entrySide, "taxCode"]
      : [templateIndex, "details", detailsIndex, entrySide, "taxCode"];
    form.setFieldValue(taxCodeNamePath, selectedAccountItem.tax_code);
  };

  return (
    <Form.List
      name={templateSetting ? "details" : [templateIndex, "details"]}
      // initialValue={[
      //   {
      //     fieldKey: 0,
      //     isListField: true,
      //     key: 0,
      //     name: 0,
      //   },
      // ]}
    >
      {(details, { add, remove }) => {
        return (
          <div>
            {details.map((field, index) => (
              <div key={field.key}>
                <Row type="flex" justify="between">
                  <Col span={6}>
                    <MarginDiv>
                      <Form.Item
                        {...field}
                        name={[index, "debit", "accountItemId"]}
                        label="勘定科目"
                        shouldUpdate={true}
                        rules={[
                          {
                            required: true,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onChange={() => {
                            onChangeAccountItem(index, "debit");
                          }}
                          filterOption={filterOption}
                          options={accountItemsOptions}
                        />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "debit", "partnerId"]} label="取引先　">
                        <SearchSelect options={freeeTemplates.partners} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "debit", "itemId"]} label="品目　　">
                        <SearchSelect options={freeeTemplates.items} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "debit", "sectionId"]} label="部門　　">
                        <SearchSelect options={freeeTemplates.sections} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "debit", "memoTagIds"]} initialValue={[]} label="メモタグ">
                        <SearchSelect options={freeeTemplates.memoTags} mode="multiple" />
                      </Form.Item>
                    </MarginDiv>
                  </Col>
                  <Col span={4}>
                    <MarginDiv>
                      <Form.Item
                        name={[index, "debit", "amount"]}
                        label="金額　"
                        rules={[
                          {
                            required: !templateSetting,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <Input disabled={templateSetting} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item
                        {...field}
                        name={[index, "debit", "taxCode"]}
                        label="税区分"
                        rules={[
                          {
                            required: true,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <Select showSearch optionFilterProp="children" filterOption={filterOption}>
                          {freeeTemplates.taxes.map((tax) => (
                            <Select.Option value={tax.code} key={tax.code}>
                              {tax.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </MarginDiv>
                  </Col>
                  <Col span={6}>
                    <MarginDiv>
                      <Form.Item
                        {...field}
                        name={[index, "credit", "accountItemId"]}
                        label="勘定科目"
                        shouldUpdate={true}
                        rules={[
                          {
                            required: true,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onChange={() => {
                            onChangeAccountItem(index, "credit");
                          }}
                          filterOption={filterOption}
                          options={accountItemsOptions}
                        />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "credit", "partnerId"]} label="取引先　">
                        <SearchSelect options={freeeTemplates.partners} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "credit", "itemId"]} label="品目　　">
                        <SearchSelect options={freeeTemplates.items} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "credit", "sectionId"]} label="部門　　">
                        <SearchSelect options={freeeTemplates.sections} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "credit", "memoTagIds"]} initialValue={[]} label="メモタグ">
                        <SearchSelect options={freeeTemplates.memoTags} mode="multiple" />
                      </Form.Item>
                    </MarginDiv>
                  </Col>
                  <Col span={4}>
                    <MarginDiv>
                      <Form.Item
                        name={[index, "credit", "amount"]}
                        label="金額　"
                        rules={[
                          {
                            required: !templateSetting,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <Input disabled={templateSetting} />
                      </Form.Item>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item
                        {...field}
                        name={[index, "credit", "taxCode"]}
                        label="税区分"
                        rules={[
                          {
                            required: true,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <Select showSearch optionFilterProp="children" filterOption={filterOption}>
                          {freeeTemplates.taxes.map((tax) => (
                            <Select.Option value={tax.code} key={tax.code}>
                              {tax.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </MarginDiv>
                  </Col>
                  <Col span={4}>
                    <MarginDiv style={{ float: "right", paddingRight: "10px" }}>
                      <Button onClick={() => remove(field.name)} disabled={details.length === 1} type="text">
                        <CloseCircleOutlined style={{ color: "red" }} />
                      </Button>
                    </MarginDiv>
                    <MarginDiv>
                      <Form.Item {...field} name={[index, "description"]}>
                        <TextArea maxLength={250} />
                      </Form.Item>
                    </MarginDiv>
                  </Col>
                </Row>
                <Collapse
                  bordered={false}
                  defaultActiveKey={[0, 1, 2, 3, 4]}
                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  style={{ marginLeft: "16px", marginRight: "16px" }}
                  className="site-collapse-custom-collapse"
                >
                  {templateSetting && (
                    <Panel header="金額の集計対象を設定" key={field.key} className="site-collapse-custom-panel">
                      {walletAddressList.map((wa) => (
                        <Row key={wa.walletAddress}>
                          <Col span={6}>
                            <div style={{ marginTop: "6px" }}>{tagRender(wa)}ウォレットの</div>
                          </Col>
                          <Col span={18}>
                            <Form.Item
                              fieldKey={field.fieldKey}
                              isListField={field.isListField}
                              key={`${field.key}-${wa.walletAddress}`}
                              name={[index, "aggregationAddress", wa.walletAddress]}
                            >
                              <Checkbox.Group options={plainOptions} />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </Panel>
                  )}
                </Collapse>
                {index + 1 < details.length && <Divider />}
              </div>
            ))}
            <Form.Item key="addRow">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button type="dashed" onClick={() => add()} style={{ width: "60%" }}>
                  <PlusOutlined /> 行を追加
                </Button>
              </div>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default TableInTemplate;
