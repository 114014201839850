import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import ProjectListHeader from "../components/ProjectListHeader";

const { Content } = Layout;

const ProjectBackground = () => {
  return (
    <div>
      <ProjectListHeader />
      <Content className="site-layout" style={{ padding: "0 30px", marginTop: 16, height: "90vh" }}>
        <Outlet />
      </Content>
    </div>
  );
};

export default ProjectBackground;
