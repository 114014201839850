import { Button } from "antd";
import { useSetRecoilState } from "recoil";
import UserList from "../components/UserList";
import RegisterUser from "../components/RegisterUser";
import { isOpenState } from "../../../state/atoms/isOpen";

const User = () => {
  const setIsOpen = useSetRecoilState(isOpenState("userRegistrationModal"));
  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        新規登録
      </Button>
      <UserList />
      <RegisterUser />
    </>
  );
};

export default User;
