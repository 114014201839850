import { PageHeader, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAuthenticator } from "@aws-amplify/ui-react";

const { Text } = Typography;

const StyledHeader = styled.header`
  border: 1px solid rgb(235, 237, 240);
  background-color: #f5f5f5;
  height: 12;
`;

const Header = () => {
  const { projectId } = useParams();
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <StyledHeader>
      <PageHeader
        title={
          <Link key="0" to="/">
            <Text>ChainChaser</Text>
          </Link>
        }
        subTitle="for sales management"
        extra={[
          <nav key="nav">
            <Link key="1" to={`/${projectId}/dashboard`}>
              ダッシュボード
            </Link>{" "}
            |{" "}
            <Link key="2" to={`/${projectId}/transaction`}>
              取引明細
            </Link>{" "}
            |{" "}
            <Link key="3" to={`/${projectId}/settings/walletAddressList`}>
              設定
            </Link>{" "}
            |{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/presentation/d/1_h5NEpUNNXVdoGOSqMIsD66yi43qNrij8XH-9ePYsjI/edit#slide=id.gc6f9e470d_0_0"
            >
              使い方
            </a>
          </nav>,
          <div key="email">
            <span>{user.attributes.email} </span>
          </div>,
          <button onClick={signOut} key="signOutButton">
            Sign Out
          </button>,
        ]}
      />
    </StyledHeader>
  );
};

export default Header;
