import { Form, Button, Switch, Input, Spin, Modal, Radio, Divider, Row, Col, Typography } from "antd";
import DatePicker from "../../../components/DatePicker";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { isOpenWithParamsState } from "../../../state/atoms/isOpenWithParamsAtom";
import { useTransferMemo, useFreeeTemplates, deleteToken } from "../../freee/api";
import { useFetchSalesSummary } from "../api";
import { notifyError, notifySuccess } from "../../../common/notify";
import FreeeTemplateEmpty from "./freeeTemplateEmpty";
import TableInTemplate from "../../freee/components/TableInTemplate";

const { Title } = Typography;

const FreeeSync = () => {
  const [form] = Form.useForm();

  const { freeeTemplates, isFreeeTemplatesLoading } = useFreeeTemplates();
  const { registerTransferMemo } = useTransferMemo();
  const { summary, isLoadingSummary } = useFetchSalesSummary();
  const [isOpenFreeeSyncModal, setIsOpenFreeeSyncModal] = useRecoilState(isOpenWithParamsState("freeeSyncModal"));

  if (isFreeeTemplatesLoading || isLoadingSummary) return;

  const handleFinish = async () => {
    const validatedValues = Object.values(await form.validateFields());

    // 登録するトグルが ON のテンプレごとに登録APIを叩く
    let isFailed = false;
    await Promise.allSettled(
      validatedValues
        .filter((v) => v.isRegistered)
        .map(async (value) => {
          try {
            await registerTransferMemo(value);
            notifySuccess(`${value.templateName}を登録しました`, { duration: 30 });
          } catch (e) {
            isFailed = true;
            console.error(e);
            notifyError(`${value.templateName}の登録ができませんでした`, {
              duration: 30,
              description: (
                <ul>
                  {e.response.data.error.message !== undefined && <li>{e.response.data.error.message}</li>}
                  {e.response.data.error.errors?.at(0).messages.map((msg, ii) => (
                    <li key={ii}>{msg}</li>
                  ))}
                  {e.response.data.error.errors?.at(1)?.messages.map((msg, ii) => (
                    <li key={ii}>{msg}</li>
                  ))}
                </ul>
              ),
            });
          }
        })
    );
    // 全て登録成功した場合はモーダルを閉じる
    !isFailed && setIsOpenFreeeSyncModal({ status: false, params: {} });
  };

  const accountItemsOptions = freeeTemplates.accountItems.map((accountItem) => {
    return { value: accountItem.id, label: accountItem.name };
  });

  const onChangeAccountItem = (accountItemIndex) => {
    const selectedAccountItem = freeeTemplates.accountItems.find(
      (accountItem) => accountItem.id === form.getFieldValue(["accountItems", accountItemIndex, "accountItem"])
    );
    form.setFieldValue(["accountItems", accountItemIndex, "taxCode"], selectedAccountItem.tax_code);
  };

  const calculateAmount = (aggregationAddress) => {
    const targetMonthSummary = summary.find((e) => e.key === isOpenFreeeSyncModal.params.month);
    if (targetMonthSummary === undefined) return 0;

    const amount = Object.entries(aggregationAddress).reduce((amount, [wa, cas]) => {
      const targetWalletDetails = targetMonthSummary.detailsByWalletAddress.find((s) => s.walletAddress === wa);

      if (cas.includes("gasJpy")) {
        amount += targetWalletDetails.gasJpy;
      }

      return (
        amount +
        targetWalletDetails.salesValueJpy.reduce((acc, cur) => {
          if (cas.includes(cur.contractAddress)) {
            return acc + cur.sales;
          } else {
            return acc;
          }
        }, 0)
      );
    }, 0);

    return Math.round(amount);
  };

  const templatesWithAmount = freeeTemplates.templates.map((template) => {
    // 金額を計算する
    const templateWithAmount = { ...template, details: [] };
    template.details.map((detail) => {
      const amount = calculateAmount(detail.aggregationAddress);
      templateWithAmount.details.push({
        ...detail,
        debit: { ...detail.debit, amount },
        credit: { ...detail.credit, amount },
      });
      templateWithAmount.isRegistered = amount > 0;
    });
    return templateWithAmount;
  });
  templatesWithAmount.length > 0 && form.setFieldsValue(templatesWithAmount);

  return (
    <>
      <Modal
        title={
          <div>
            <Title level={4} style={{ display: "inline" }}>
              freee連携 振替伝票に登録
            </Title>
            {"　　"}
            <span>ログイン中の事業所:</span>{" "}
            <span style={{ fontWeight: "bold" }}>{freeeTemplates.company.displayName}</span>
            {"　"}
            <Button
              onClick={async () => {
                await deleteToken();
                window.open(process.env.REACT_APP_FREEE_AUTH_URL, "_blank");
                setIsOpenFreeeSyncModal({ status: false, params: {} });
              }}
              type="link"
              size="small"
            >
              事業所を変更
            </Button>
          </div>
        }
        width="95%"
        visible={isOpenFreeeSyncModal.status}
        okText="freee に登録"
        cancelText="戻る"
        onOk={() => handleFinish()}
        onCancel={() => setIsOpenFreeeSyncModal({ status: false, params: {} })}
        getContainer={false}
        okButtonProps={{ disabled: freeeTemplates.templates.length === 0 }}
      >
        {freeeTemplates.templates.length === 0 && (
          <div>
            <FreeeTemplateEmpty />
          </div>
        )}
        <Form form={form}>
          <Spin spinning={isFreeeTemplatesLoading || isLoadingSummary}>
            {templatesWithAmount.map((template, templateIndex) => {
              return (
                <div key={templateIndex}>
                  <Row justify="space-between">
                    <Title level={4}>{template.templateName}</Title>
                    <Form.Item name={[templateIndex, "templateName"]} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={[templateIndex, "isRegistered"]} valuePropName="checked">
                      <Switch
                        defaultChecked={template.isRegistered}
                        checkedChildren="登録する　"
                        unCheckedChildren="登録しない"
                      />
                    </Form.Item>
                  </Row>
                  <Row type="flex" justify="start">
                    <Col span={6}>
                      <Form.Item
                        label="発生日"
                        name={[templateIndex, "issueDate"]}
                        rules={[
                          {
                            required: template.isRegistered,
                            message: "必須項目です",
                          },
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name={[templateIndex, "adjustment"]} initialValue={false}>
                        <Radio.Group>
                          <Radio value={false}>日常仕訳</Radio>
                          <Radio value={true}>決算整理仕訳</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" justify="between" style={{ backgroundColor: "lightgray", padding: "10px" }}>
                    <Col span={6}>
                      <div>借方勘定科目</div>
                      <div>取引先・品目・部門・メモタグ</div>
                    </Col>
                    <Col span={4}>
                      <div>借方金額</div>
                      <div>借方税区分・税額</div>
                    </Col>
                    <Col span={6}>
                      <div>貸方勘定科目</div>
                      <div>取引先・品目・部門・メモタグ</div>
                    </Col>
                    <Col span={4}>
                      <div>貸方金額</div>
                      <div>貸方税区分・税額</div>
                    </Col>
                    <Col span={4}>備考</Col>
                  </Row>
                  <TableInTemplate templateSetting={false} templateIndex={templateIndex} />
                  <div style={{ float: "right" }}>
                    ※貸借が一致していない、または合計金額が0円の振替伝票は登録できません。
                  </div>
                  {templateIndex + 1 < freeeTemplates.templates.length && <Divider />}
                </div>
              );
            })}
          </Spin>
        </Form>
      </Modal>
    </>
  );
};
export default FreeeSync;
