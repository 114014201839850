import Balance from "../components/Balance";
import EditWalletAddress from "../components/EditWalletAddress";
import RegisterWalletAddress from "../components/RegisterWalletAddress";
import WalletAddressList from "../components/WalletAddressList";

const WalletAddress = () => {
  return (
    <>
      <Balance />
      <RegisterWalletAddress />
      <EditWalletAddress />
      <WalletAddressList />
    </>
  );
};

export default WalletAddress;
