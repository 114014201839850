import { selectorFamily } from "recoil";
import { walletAddressListState } from "../atoms/walletAddressListAtom";
import { selectedWalletAddressState } from "../atoms/selectedWalletAddressAtom";
import { transactionQuery } from "./transactionQuery";

export const filterTransactionQuery = selectorFamily({
  key: "FilterTransaction",
  get:
    (projectId) =>
    async ({ get }) => {
      const transactionList = get(transactionQuery(projectId));
      const selectedWalletAddress = get(selectedWalletAddressState(projectId));

      const selectedWalletAddressList = selectedWalletAddress.map((wa) => wa.value);

      if (selectedWalletAddressList.includes("other")) {
        const walletAddresses = get(walletAddressListState(projectId));
        const notSelectedWalletAddressList = walletAddresses
          .filter((wa) => !selectedWalletAddressList.includes(wa.walletAddress))
          .map((wa) => wa.walletAddress);

        // 全て選択中の場合はフィルタリング不要のためそのまま返す
        if (notSelectedWalletAddressList.length === 0) return transactionList;

        return transactionList.reduce((results, current) => {
          // 選択中の wallet が関連する tx は返す
          if (selectedWalletAddressList.includes(current.from) || selectedWalletAddressList.includes(current.to)) {
            results.push(current);
            return results;
          } else if (
            current.transferDetail.find(
              (td) => selectedWalletAddressList.includes(td.from) || selectedWalletAddressList.includes(td.to)
            )
          ) {
            results.push(current);
            return results;
          }

          // 未選択の wallet が関連する tx は返さず、選択中のウォレットにも未選択のウォレットにも関連しない tx は返す
          if (
            notSelectedWalletAddressList.includes(current.from) ||
            notSelectedWalletAddressList.includes(current.to)
          ) {
            return results;
          } else if (
            current.transferDetail.find(
              (td) => notSelectedWalletAddressList.includes(td.from) || notSelectedWalletAddressList.includes(td.to)
            )
          ) {
            return results;
          } else {
            results.push(current);
            return results;
          }
        }, []);
      } else {
        return transactionList.reduce((results, current) => {
          // 選択中の wallet が関連する tx は返す
          if (selectedWalletAddressList.includes(current.from) || selectedWalletAddressList.includes(current.to)) {
            results.push(current);
          } else if (
            current.transferDetail.find(
              (td) => selectedWalletAddressList.includes(td.from) || selectedWalletAddressList.includes(td.to)
            )
          ) {
            results.push(current);
          }

          return results;
        }, []);
      }
    },
});
