import { useState } from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import { isAddress } from "ethers/lib/utils";
import { CirclePicker } from "react-color";
import { useWalletAddress } from "../api";

const layout = {
  labelCol: {
    offset: 1,
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const RegisterWalletAddress = () => {
  const [form] = Form.useForm();

  const { walletAddressList, registerWalletAddress } = useWalletAddress();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [themeColorHex, setThemeColorHex] = useState("#f44336");

  const [loading, setLoading] = useState(false);

  const onRegisterWalletAddress = async () => {
    setLoading(true);
    form.validateFields().then(async (values) => {
      await registerWalletAddress(values);
      setIsOpen(false);
      form.resetFields();
      setLoading(false);

      // // お知らせmodal表示
      // let secondsToGo = 9;
      // const modal = Modal.success({
      //   title: (
      //     <>
      //       <div>新しいウォレットアドレスの登録が完了しました！</div>
      //       <div>データの取得が終わるまで、もうしばらくお待ちください。</div>
      //     </>
      //   ),
      //   content: `${secondsToGo}秒後にウインドウは自動で閉じます。`,
      // });
      // const timer = setInterval(() => {
      //   secondsToGo -= 1;
      //   if (secondsToGo > 0) {
      //     modal.update({
      //       content: `${secondsToGo}秒後にウインドウは自動で閉じます。`,
      //     });
      //   }
      // }, 1000);
      // setTimeout(() => {
      //   modal.destroy();
      //   clearInterval(timer);
      // }, secondsToGo * 1000);
    });
  };

  const handleColorChange = (color) => {
    setThemeColorHex(color.hex);
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        新規登録
      </Button>
      <Modal
        title="新しいウォレットアドレスを登録"
        width={1000}
        visible={modalIsOpen}
        okText="登録"
        cancelText="戻る"
        onOk={onRegisterWalletAddress}
        onCancel={() => setIsOpen(false)}
      >
        <Spin spinning={loading}>
          <Form {...layout} form={form} name="control-hooks">
            <Form.Item
              name="walletAddress"
              label="アドレス"
              rules={[
                {
                  required: true,
                  message: "登録したいウォレットアドレスを入力してください",
                },
                {
                  message: "このアドレスは既に登録されています",
                  validator: (_, value) => {
                    if (
                      typeof value !== "undefined" &&
                      value !== "" &&
                      walletAddressList.map((wa) => wa.walletAddress.toLowerCase()).includes(value.toLowerCase())
                    ) {
                      return Promise.reject(new Error("This address has already been registered."));
                    }
                    return Promise.resolve();
                  },
                },
                {
                  message: "このアドレスは有効ではありません",
                  validator: (_, value) => {
                    if (typeof value !== "undefined" && value !== "" && !isAddress(value)) {
                      return Promise.reject(new Error("This address is invalid."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="0xAb..." />
            </Form.Item>

            <Form.Item
              name="name"
              label="表示名"
              rules={[
                {
                  required: true,
                  message: "好きな名前を入力してください",
                },
              ]}
            >
              <Input placeholder="○○用" />
            </Form.Item>

            <Form.Item name="memo" label="メモ">
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="themeColor"
              label="テーマカラー"
              rules={[
                {
                  required: true,
                  message: "好きな色を選択してください",
                },
              ]}
              getValueFromEvent={(args) => args.hex}
              initialValue={themeColorHex}
            >
              <CirclePicker width="690" color={themeColorHex} onChange={handleColorChange} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default RegisterWalletAddress;
