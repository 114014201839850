import { Alert, List } from "antd";
import { useFetchCountTxs } from "../api";

const SyncDataList = () => {
  const { countTxs, loadingCountTxs } = useFetchCountTxs();

  const getLabel = (name) => {
    switch (name) {
      case "countAllTxs":
        return "同期済みトランザクション数";
      case "countTxsWithTransferDetail":
        return "トランザクション詳細取得済";
      case "countTxsWithoutTransferDetail":
        return "トランザクション詳細未取得";
      default:
        return;
    }
  };

  return (
    <>
      {countTxs.length === 3 && countTxs[2].count > 0 && (
        <Alert message="現在取引の詳細を取得中です。完了までもうしばらくお待ちください。" type="warning" />
      )}
      <div>{"　"}</div>
      <List
        loading={loadingCountTxs}
        size="large"
        bordered
        dataSource={countTxs}
        renderItem={(item) => (
          <List.Item>
            {getLabel(item.name)}: {item.count}件
          </List.Item>
        )}
      />
    </>
  );
};

export default SyncDataList;
