import { atomFamily } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

//参考：https://github.com/TomoyukiMatsuda/React-pra/tree/next-sample/axios-recoil/next-sample/src/grobalStates
export const selectedContractInMethodCallCountState = atomFamily({
  key: "SelectedContractInMethodCallCount",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
