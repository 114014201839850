import { atom, selector } from "recoil";
import { fetchProjects } from "../../features/project/api";

//参考：https://github.com/TomoyukiMatsuda/React-pra/tree/next-sample/axios-recoil/next-sample/src/grobalStates
export const projectListState = atom({
  key: "ProjectList",
  default: selector({
    key: "savedProjectList",
    get: async () => {
      try {
        const response = await fetchProjects();
        return response;
      } catch (error) {
        throw new Error(error);
      }
    },
  }),
});
