import { atomFamily } from "recoil";

export const freeeTemplatesState = atomFamily({
  key: "FreeeTemplates",
  default: {
    templates: [],
    accountItems: [],
    partners: [],
    taxes: [],
    walletables: [],
    items: [],
    sections: [],
    memoTags: [],
    company: {},
  },
});
